import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Depository, Experience, Gender, GenderString, kraStatus, MarriedStatus, PageLinks, PersonVideVerifyStatus, Status, Yes_or_No, AccountStatus, SGBUpcomingStatus } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { ServerAPI } from "../common/ServerAPI";
import { getDisplayData, getDisplaySelectOption, getDownloadFile, getDownloadURLExtension, getFormattedLocalDateTime, getURLExtension, numberWithCommas } from "../common/Utilities";

const SGBDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const ISINNumber = location.state;
    const { setShowPreloader } = useAppStateAPI();
    const [SGBDetails, setSGBDetails] = useState<APIData.SGBAdd>();

    useEffect(() => {
        loadSGBDetail(ISINNumber);
        console.log(ISINNumber);
    }, [ISINNumber]);

    const loadSGBDetail = (ISINNumber: string) => {
        if (ISINNumber != '') {
            setShowPreloader(true);
            ServerAPI.getSGBDetail(ISINNumber).then((res) => {
                console.log(res);
                setSGBDetails(res);
            }).finally(() => {
                setShowPreloader(false)
            })
        }
    }

    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pt-4">
                                        <div className="page-title-rightds">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item " onClick={() => navigate(PageLinks.SGB_LIST)}>Sovereign Gold Bond</li>
                                                <li className="breadcrumb-item active"><b>Sovereign Gold Bond Details</b></li>

                                            </ol>
                                        </div>
                                        <div className='cursor-pointer' onClick={() => navigate(-1)}>
                                            <p className='mb-0 active'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                                                <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                                            </svg>
                                                <span> Back </span></p>
                                        </div>


                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header col-xl-12 w-100">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className="my-3 header">Sovereign Gold Bond Details</h5>
                                                        <div className="">
                                                            <a className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2" onClick={() => navigate(PageLinks.SGB_ADD, { state: { id: ISINNumber, mode: 'edit' } })} ><img src="../../assets/images/edit.svg" style={{ marginBottom: "4px", width: "16px" }} /> Edit</a>
                                                        </div>
                                                    </div>

                                                    <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                        {/* <div className="card-header">
                                                            <h5 className="m-0 header"> Personal Details </h5>
                                                        </div> */}
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Issuer Name</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_issuer_name)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Bond Name</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_name)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Nature of Instrument</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_nature_of_instrument, 'bond_nature_of_instrument')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Security Type</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_security_type, 'bond_security_type')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ST</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_st)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Security Type Code</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_security_type_code)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Security Code</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_security_code)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Govt. Guarantee</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_govt_guranatee, 'bond_govt_guranatee')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ISIN Number</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_isin_number)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Face Value (₹)</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_face_value)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">issue Price (₹) </p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_issue_price)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Price per Gram (₹)</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bonds_price_per_gram)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Purchase Limit (In years)</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_purchase_limit, 'bond_purchase_limit')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Coupon Rate (Returns %)</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_coupon_rate)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Yeild (%)</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bonds_yeild)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Interest Type</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_interest_type, 'bond_interest_type')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Interest Calculate by</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_calc_int_on_holidays, 'bond_calc_int_on_holidays')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Allotment Date</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_allotment_date)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Issue Opening Date</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_opening_date)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Issue Closing Date</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_closing_date)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Next Interest Payment Date</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bonds_next_interest_payment_date)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Maturity after</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_maturity_date)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">DMAT Book Closer Date</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_dmat_book_closer_date)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Exit Option Available</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.exit_option_available, 'exit_option_available')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Calc Int on Holiday</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_calc_int_on_holidays, 'bond_calc_int_on_holidays')}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Calc Int on Maturity</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_int_on_maturity, 'bond_int_on_maturity')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Exchange</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_exchange, 'bond_exchange')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Listing</p>
                                                                    <h6>{getDisplaySelectOption(SGBDetails?.bond_listing, 'bond_listing')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">RBI Notice Link</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(SGBDetails?.bonds_banner_rcb_notice_link)}>{getDisplayData(SGBDetails?.bonds_banner_rcb_notice_link)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Bond Logo</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(SGBDetails?.bond_logo)}>{getDownloadURLExtension(SGBDetails?.bond_logo)}</h6>
                                                                </div>

                                                                <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                                                    <p className="detail-title">About this - Investing In Sovereign Gold Bonds (Sgbs)</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_about_this_ipo)}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Category Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">I - Institutional</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_category_institutional)}%</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">II - Non-Institutiona1</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_category_non_institutional)}%</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">III - HNI</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_category_hni)}%</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">IV - Retail</p>
                                                                    <h6>{getDisplayData(SGBDetails?.bond_category_retail)}%</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="row">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h5 className="m-0 header">Coupon Details</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Coupon Date</p>
                                                                        <h6>{getDisplayData(SGBDetails?.bond_coupon_date)}</h6>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Coupon Amount</p>
                                                                        <h6>{numberWithCommas(SGBDetails?.bond_coupon_amount)}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Interest Calculate by Yearly</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="table-responsive " data-pattern="priority-columns">
                                                                <table id="tech-companies-1" className="table table table-bordered">
                                                                    <thead className="city-table">
                                                                        <tr>
                                                                            <th>Sr. No</th>
                                                                            <th>Interest Date</th>
                                                                            <th>Interest Rate</th>
                                                                            <th>Interest Amount</th>
                                                                            <th>No. of Days</th>
                                                                        </tr>
                                                                    </thead>
                                                                    {
                                                                        SGBDetails?.bond_cashflow_details != undefined && SGBDetails?.bond_cashflow_details.length > 0 ?
                                                                            <tbody>
                                                                                {
                                                                                    SGBDetails?.bond_cashflow_details.map((cashFlow: APIData.BondCashflowDetails, index: number) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{cashFlow.bond_cashflow_date}</td>
                                                                                                <td>{cashFlow.bond_cashflow_type}</td>
                                                                                                <td>{cashFlow.bond_cashflow_amount}</td>
                                                                                                <td>{cashFlow.bond_cashflow_days}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                            : <tbody>
                                                                                <tr className='py-4'>
                                                                                    <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                                </tr>
                                                                            </tbody>
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Brand Tags</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-12">
                                                                    {
                                                                        SGBDetails?.bond_brand_tags != undefined && SGBDetails.bond_brand_tags.length > 0 ?
                                                                            SGBDetails.bond_brand_tags.map((tags: APIData.BondBrandTags, index: number) => {
                                                                                return (
                                                                                    <div className={`col-3 brand-tag${tags.bond_brand_tag_id != null && tags.bond_brand_tag_id != 0 ? '-active' : ''} p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}>{getDisplayData(tags.brand_tag_name)}</div>
                                                                                )
                                                                            }) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Eligible Investors Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-12 ">
                                                                    {
                                                                        SGBDetails?.bond_eligible_investors != undefined && SGBDetails.bond_eligible_investors.length > 0 ?
                                                                            SGBDetails.bond_eligible_investors.map((inv: APIData.BondEligibleInvestors, index: number) => {
                                                                                return (
                                                                                    <div className={`col brand-tag${inv.bond_eligible_investor_id != null && inv.bond_eligible_investor_id != 0 ? '-active' : ''} p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}>{getDisplayData(inv.eligible_investor_name)}</div>
                                                                                )
                                                                            }) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="row">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h5 className="m-0 header">Banner Content</h5>
                                                            </div>
                                                            <div className="card-body">

                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 ">
                                                                        <p className="detail-title">Title Text</p>
                                                                        <h6>{getDisplayData(SGBDetails?.bonds_banner_content_title)}</h6>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                        <p className="detail-title">Sub Title Text</p>
                                                                        <h6>{getDisplayData(SGBDetails?.bonds_banner_content_sub_title)}</h6>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                        <p className="detail-title">Banner image</p>
                                                                        <h6>{getDownloadURLExtension(SGBDetails?.bonds_banner_content_image)}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-md-4 col-sm-6  mb-4 col-sm-3 ">
                                                                        <p className="detail-title">Key Highlights</p>
                                                                        {
                                                                            SGBDetails?.bond_highlights != undefined && SGBDetails?.bond_highlights.length > 0 ?
                                                                                <ul>
                                                                                    {
                                                                                        SGBDetails?.bond_highlights.map((hight: APIData.bond_highlights, index: number) => {
                                                                                            return (
                                                                                                <li>
                                                                                                    <h6>{getDisplayData(hight.bond_highlights_title)}</h6>
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul> : ""

                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="row">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h5 className="m-0 header">Sovereign Gold Bond Benefits</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                {
                                                                    SGBDetails?.bond_benefits != undefined && SGBDetails?.bond_benefits.length > 0 ?
                                                                        <ul>
                                                                            {
                                                                                SGBDetails?.bond_benefits.map((benefit: APIData.bond_benefits, index: number) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <div className="row">
                                                                                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                                    <p className="detail-title">Title Text</p>
                                                                                                    <h6>{getDisplayData(benefit.bond_benefits_title)}</h6>
                                                                                                </div>
                                                                                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                                    <p className="detail-title">Icon</p>
                                                                                                    <h6>{getDownloadURLExtension(benefit.bond_benefits_icon)}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                                    <p className="detail-title">Description</p>
                                                                                                    <h6>{getDisplayData(benefit.bond_benefits_description)}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul> : <tbody>
                                                                            <tr className='py-4'>
                                                                                <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                            </tr>
                                                                        </tbody>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="row">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h5 className="m-0 header">Upload ISSUE DOCUMENTS</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Download</p>
                                                                        <h6>{getDownloadURLExtension(SGBDetails?.bond_issue_document)}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="row">
                                                        <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                            <div className="card-header d-flex justify-content-between align-items-center">
                                                                <div className='d-flex justify-content-start'>
                                                                    <h5 className="m-0 header">Upcoming issues</h5>
                                                                </div>
                                                                <div className="form-check form-switch mx-2">
                                                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Disable/Enable</label>
                                                                    <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                                                        checked={SGBDetails?.bond_upcoming_issues_status == SGBUpcomingStatus.Active ? true : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                        <thead className="city-table">
                                                                            <tr>
                                                                                <th>Sr. No</th>
                                                                                <th>Issue Name</th>
                                                                                <th>Subscription Dates</th>
                                                                                <th>Allotment Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {
                                                                            SGBDetails?.bond_upcoming_issues != undefined && SGBDetails?.bond_upcoming_issues.length > 0 ?
                                                                                <tbody>
                                                                                    {
                                                                                        SGBDetails?.bond_upcoming_issues.map((upcoming: APIData.bond_upcoming_issues, index: number) => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td>{index + 1}</td>
                                                                                                    <td>{upcoming.bond_upcoming_issues_name}</td>
                                                                                                    <td>{upcoming.bond_upcoming_issues_subscription_start_date}- {upcoming.bond_upcoming_issues_subscription_end_date}</td>
                                                                                                    <td>{upcoming.bond_upcoming_issues_allotment_date}</td>
                                                                                                </tr>

                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                                : <tbody>
                                                                                    <tr className='py-4'>
                                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                        }
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                            <div className="card-header">
                                                                <h5 className="m-0 header"> Disclaimer </h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <h6>{getDisplayData(SGBDetails?.bond_disclaimer)}</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {/* <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header">ASBA Details</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <h6 style={{ textDecoration: "underline", cursor: "pointer" }} ><img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px", paddingRight: "5px" }} />{SGBDetails.}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header">Terms & Conditions Link</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <h6 style={{ textDecoration: "underline", cursor: "pointer" }}><img src="../../assets/images/link.svg" style={{ width: "18px", marginBottom: "2px", paddingRight: "5px" }} />{getDisplayData(SGBDetails?.bond_term_condition_link)}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default SGBDetails;