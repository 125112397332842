import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { BondType, Depository, PageLinks } from '../common/Constants';
import { APIData } from '../common/DataTypes';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { InitialData } from '../common/InitialValue';
import { ServerAPI } from '../common/ServerAPI';
import { getDealerStatus, getDisplayData, getDownloadFile, getFormattedLocalDateTime, getOrderStatus, getOrderTypeFilter, numberWithCommas } from '../common/Utilities';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';


const CustomerOrderList: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value);
    const [bondSelectOption, setBondSelectOption] = useState("all");

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [orderList, setOrderList] = useState<APIData.CustomerOrderList[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const searchref = React.createRef();

    useEffect(() => {
        loadOrderList();
    }, [pageNumber, search_text, toData, fromDate, preData, open, bondSelectOption])

    const loadOrderList = () => {
        setShowPreloader(true);
        ServerAPI.getCustomerOrderList(pageNumber, search_text, toData, fromDate, bondSelectOption).then((res) => {
            try {
                setOrderList(res.data);
                setTotalCount(res.count);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Order Book </a></li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            {/* <div className="card-header col-xl-12 w-100"> */}
                                            <div className="row" style={{ paddingBottom: "10px" }}>
                                                <div className="col-xl-4">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                                        <form className="app-search card-title p-2 m-0">
                                                            <div className="position-relative">
                                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="col-xl-8 text-end">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative ">
                                                                <select className='select-style' name='bond_type'
                                                                    onChange={(e) => setBondSelectOption(e.target.value)}
                                                                >
                                                                    <option value="all">All</option>
                                                                    <option value="IPO">IPO</option>
                                                                    <option value="BOND">Bond</option>
                                                                    <option value="SGB">SGB</option>
                                                                </select>
                                                            </div>
                                                        </form>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                <DatePicker
                                                                    selected={fromDate}
                                                                    onChange={(date: Date) => setFromDate(date)}
                                                                    placeholderText="From Date"
                                                                    className='date-picker-style'
                                                                    customInput={(<CustomInput />)}
                                                                />

                                                            </div>
                                                        </form>
                                                        <h4 className="card-title p-2 m-0">TO</h4>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                <DatePicker
                                                                    selected={toData}
                                                                    onChange={(date: Date) => setToData(date)}
                                                                    placeholderText="To Date"
                                                                    className='date-picker-style'
                                                                    customInput={(<CustomInput />)}
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-rep-plugin">
                                                <div className="table-responsive " data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Order ID</th>
                                                                <th>Order Date & Time</th>
                                                                <th>Order Type</th>
                                                                <th>Customer Name</th>
                                                                <th>Bond / IPO Name</th>
                                                                <th>No. Of Quantity</th>
                                                                <th>Total Amount</th>
                                                                <th>Order Status</th>
                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            orderList != undefined && orderList.length > 0 ?
                                                                <>
                                                                    {
                                                                        orderList.map((res: APIData.CustomerOrderList, i: number) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td> {i + 1} </td>
                                                                                    <td>{getDisplayData(res.order_id)}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.order_datetime)}</td>
                                                                                    <td>{getOrderTypeFilter(res.order_type)}</td>
                                                                                    <td>{getDisplayData(res.customer_name)}</td>
                                                                                    <td>{getDisplayData(res.bond_name)}</td>
                                                                                    <td>{getDisplayData(res.order_quantity)}</td>
                                                                                    <td>₹ {numberWithCommas(res.order_amount)}</td>
                                                                                    <td>{getOrderStatus(res.order_status)}</td>
                                                                                    <td className="city-action-rable-row ">
                                                                                        <i className="fas fa-eye table-icons pe-1"
                                                                                            onClick={() => navigate(PageLinks.CUSTOMER_BOOK_DETAIL_VIEW, {
                                                                                                state: { type: res.order_type, id: res.order_id }
                                                                                            })}
                                                                                        ></i>
                                                                                        {
                                                                                            res.order_type != 4 ?
                                                                                                <img src='../../assets/images/download.svg' style={{ width: '15px', margin: "0px 5px", cursor: "pointer" }}
                                                                                                    onClick={() => getDownloadFile(res.order_pdf)}
                                                                                                ></img> : ""
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        }
                                                    </table>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-5">
                                                    {totalCount > 10 ?
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {orderList.length} of {totalCount} entries</div> :
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                                        <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                                            setPageNumber(Number(value))
                                                        }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default CustomerOrderList;

