import { useAppState, StateActionTypes } from "./AppState";

export const useAppStateAPI = () => {
    const { state, dispatch } = useAppState();

    return {
        showPreloader: state.showPreloader,
        setShowPreloader: (showPreloader) => dispatch({ type: StateActionTypes.SET_SHOW_PRELOADER, showPreloader: showPreloader }),

        activeColor: state.activeColor,
        setActiveColor: (activeColor) => dispatch({ type: StateActionTypes.SET_ACTIVE_COLOR, activeColor: activeColor }),

        activeMenuColor: state.activeMenuColor,
        setActiveMenuColor: (activeMenuColor) => dispatch({ type: StateActionTypes.SET_ACTIVE_MENU_COLOR, activeMenuColor: activeMenuColor }),

        activeHeadMenuColor: state.activeHeadMenuColor,
        setActiveHeadMenuColor: (activeHeadMenuColor) => dispatch({ type: StateActionTypes.SET_ACTIVE_HEAD_MENU_COLOR, activeHeadMenuColor: activeHeadMenuColor }),

    }
}

