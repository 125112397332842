import Pagination from '@mui/material/Pagination';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { BondIPOStatus, Depository, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData, FormDataTypes } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { InitialData } from '../common/InitialValue';
import toast from 'react-hot-toast';
import { frameSelectOptions, getURLExtension, localStringToIST } from '../common/Utilities';
import { useDropzone } from 'react-dropzone';

var dmp: string = '';
var dmpIndex: number = 0;

const BondAdd: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [userList, setUserList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<APIData.BondAdd>(InitialData.BondAdd);
    const [NDCList, setNDCList] = useState<any[]>(NCDDetailsList);
    const [uploadFeild, setUploadFeild] = useState<{ feild_type: string, inx: number }>({ feild_type: '', inx: 0 });
    const [addIssuerAbout, setAddIssuerAbout] = useState<any | undefined>(EditorState.createEmpty());

    const [addComments, setAddComments] = useState<any | undefined>(EditorState.createEmpty());
    const [addComments_text, setAddComments_text] = useState("");
    const formikRef = useRef<FormikProps<APIData.BondAdd>>(null);
    const [addKeyHeighlights, setAddKeyHeighlights] = useState<any | undefined>(EditorState.createEmpty());
    const [bondID, setBondID] = useState(0);
    const [issueDate, setIssueDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [firstIPDate, setFirstIPDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [maturityDate, setMaturityDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [couponDate, setCouponDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [bookCloseDate, setBookCloseDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [DMATBookCloserDate, setDMATBookCloserDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [bondRatingOptions, setBondRatingOptions] = useState<FormDataTypes.SelectOption[]>([]);

    const [issueDateCal, setissueDateCal] = useState<any>();
    const [interestDateCal, setinterestDateCal] = useState<any>();
    const [prevDate, setPrevDate] = useState<any>();

    const handleChangeIssueDate = (newValue: Dayjs | null) => {
        setissueDateCal(newValue?.format('MM/DD/YYYY').toString())
        setIssueDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_issue_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };
    const handleChangeFirstIPDate = (newValue: Dayjs | null) => {
        setFirstIPDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_ip_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };
    const handleChangeMaturityDate = (newValue: Dayjs | null) => {
        setMaturityDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_maturity_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };
    const handleChangeCouponDate = (newValue: Dayjs | null) => {
        setCouponDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_coupon_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };
    const handleChangeBookCloserDate = (newValue: Dayjs | null) => {
        setBookCloseDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_closer_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };
    const handleChangeDMATBookCloserDate = (newValue: Dayjs | null) => {
        setDMATBookCloserDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_dmat_book_closer_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };

    // useEffect(() => {
    //     if (issueDateCal != undefined && interestDateCal != undefined) {
    //         const date1: any = new Date(issueDateCal);
    //         const date2: any = new Date(interestDateCal);
    //         const diffTime = Math.abs(date2 - date1);
    //         const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //         console.log(diffDays)
    //     }
    // }, [issueDateCal, interestDateCal])

    const handleCalculateDay = (value: any, index: number) => {
        console.log(index);
        if (formikInitialData.bond_issue_date != undefined) {
            if (value != undefined && index == 0) {
                const date1: any = new Date(formikInitialData.bond_issue_date);
                const date2: any = new Date(value);
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                setPrevDate(value);
                return diffDays
            } else {
                const date1: any = new Date(prevDate);
                const date2: any = new Date(value);
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                setPrevDate(value);
                return diffDays
            }
        } else {
            toast.error('Please select the issue date')
        }
    }

    useEffect(() => {
        dmp = uploadFeild.feild_type;
        dmpIndex = uploadFeild.inx;
    }, [uploadFeild])

    const onEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddComments(editorState);
        // setAddComments_text(htmlResult);
        setFormikInitialData(({ ...formikInitialData, bond_key_highlights: htmlResult }));
    };

    const onIssuerAboutEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddIssuerAbout(editorState);
        // setFormikInitialData(({...formikInitialData, bond_issuer_details.: htmlResult}));
    };

    const onKeyHeighlightsEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddKeyHeighlights(editorState);
        setFormikInitialData(({ ...formikInitialData, bond_key_highlights: htmlResult }));
    };

    function DateIcon() {
        return (
            <SvgIcon viewBox="0 0 72.872 83.283" style={{ padding: "2px" }}>
                <path id="Path_27181" data-name="Path 27181" d="M-1078.15,955.569v7.808h-72.872v-7.808a7.809,7.809,0,0,1,7.808-7.808h7.807v-7.808a2.61,2.61,0,0,1,2.6-2.6h5.205a2.61,2.61,0,0,1,2.6,2.6v7.808h20.82v-7.808a2.609,2.609,0,0,1,2.6-2.6h5.2a2.609,2.609,0,0,1,2.6,2.6v7.808h7.808A7.81,7.81,0,0,1-1078.15,955.569Zm-72.872,13.013h72.872v44.243a7.811,7.811,0,0,1-7.808,7.809h-57.256a7.81,7.81,0,0,1-7.808-7.809Zm10.41,18.869a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.821-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.82-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Z" transform="translate(1151.022 -937.351)" />
            </SvgIcon>
        );
    }

    // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    const [value, setValue] = React.useState<Dayjs | null>(
        dayjs('2014-08-18T21:11:54'),
    );

    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (location.state.mode && location.state.mode == 'add') {
            loadBrandTags();
            loadEligibleInvestor();
        }
        if (location.state.mode && location.state.mode == 'edit') {
            loadIntitalBOND(location.state.id);
        }
        loadBondRatingList();
    }, [])

    const loadBondRatingList = () => {
        setShowPreloader(true);
        ServerAPI.getBondRatingList().then((res) => {
            try {
                let Options = frameSelectOptions(res.message, 'bond_rating_list_id', 'bond_rating_name');
                setBondRatingOptions(Options);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
    }

    const loadIntitalBOND = (id: number) => {
        if (id) {
            setShowPreloader(true);
            ServerAPI.bondDetail(id).then((res) => {
                console.log(res);
                setBondID(res.bond_id);
                setFormikInitialData(({ ...res }));
            }).finally(() => {
                setShowPreloader(false)
            })
        }
    }

    const loadBrandTags = () => {
        setShowPreloader(true);
        ServerAPI.getBrandTagsList().then((res) => {
            if (formikRef.current?.values) {
                var currValues: APIData.BondAdd = ({ ...formikRef.current.values });
                if ((currValues.bond_brand_tags).length == 0) {
                    res.brand_tags.map((tags: APIData.BondBrandTags, index: number) => {
                        currValues.bond_brand_tags.push({
                            brand_tag_id: tags.bond_brand_tag_id,
                            brand_tag_name: tags.brand_tag_name,
                            bond_brand_tag_id: null
                        })
                    })
                    setFormikInitialData(({ ...formikInitialData }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const loadEligibleInvestor = () => {
        setShowPreloader(true);
        ServerAPI.getEligibleInvestorList().then((res) => {
            if (formikRef.current?.values) {
                var currValues: APIData.BondAdd = ({ ...formikRef.current.values });
                if ((currValues.bond_eligible_investors).length == 0) {
                    res.eligible_investors.map((inv: APIData.BondEligibleInvestors, index: number) => {
                        currValues.bond_eligible_investors.push({
                            eligible_investor_id: inv.bond_eligible_investor_id,
                            eligible_investor_name: inv.eligible_investor_name,
                            bond_eligible_investor_id: null
                        })
                    })
                    setFormikInitialData(({ ...formikInitialData }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })

    }

    const updateBrandTags = (checked: boolean, index: number) => {
        console.log(checked)
        console.log(index)
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = ({ ...formikRef.current.values });
            currValues.bond_brand_tags.map((tags: APIData.BondBrandTags) => {
                if (currValues.bond_brand_tags.filter((e, i) => i == index).length == 1) {
                    currValues.bond_brand_tags[index].bond_brand_tag_id = checked == true ? index + 1 : null;
                    // currValues.bond_brand_tags[index].brand_tag_checked = !checked
                }
            })
            setFormikInitialData(({ ...formikInitialData }))
        }
    }
    const updateEligibleTags = (checked: boolean, index: number) => {
        console.log(checked)
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = ({ ...formikRef.current.values });
            currValues.bond_eligible_investors.map((tags: APIData.BondEligibleInvestors) => {
                if (currValues.bond_eligible_investors.filter((e, i) => i == index).length == 1) {
                    currValues.bond_eligible_investors[index].bond_eligible_investor_id = checked == true ? index + 1 : null;
                    // currValues.bond_brand_tags[index].brand_tag_checked = !checked
                }
            })
            setFormikInitialData(({ ...formikInitialData }))
        }
    }

    useEffect(() => {
        // console.log(formikInitialData)
    }, [formikInitialData])

    useEffect(() => {
        suspended_list();
    }, [pageNumber, search_text, toData, fromDate, preData, open])


    const suspended_list = () => {
        setShowPreloader(true);
        ServerAPI.SuspentedUserList(pageNumber, search_text, toData, fromDate).then((res) => {
            setUserList(res.datas);
            setTotalCount(res.total_count);

        }).finally(() => {
            setShowPreloader(false);
        })

    }

    const updateNDCList = () => {
        let updatedList = NDCList;
        setNDCList(prev => ({ ...prev, header: [...prev, { name: 'Serial 2' }] }))
    }

    useEffect(() => {
        // console.log(NDCList);
    }, [NDCList])

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
        acceptedFiles.map((file: any) => {
            loadUploadedfile(file);
        });
        fileRejections.forEach((file: any) => {
            file.errors.forEach((err: any) => {
                if (err.code === "dimension") {
                    toast.error(`Error: ${err.message}`);
                }
            });
        });

    }, [])
    const { getRootProps, getInputProps } = useDropzone(
        {
            onDrop,
            multiple: true
        }
    );

    const loadUploadedfile = (file: any) => {
        setShowPreloader(true);
        ServerAPI.setUploadedFile(file).then(response => {
            if (response.filename != undefined && response.filename != "") {
                console.log(dmp);
                if (formikRef.current?.values) {
                    var currValues: APIData.BondAdd = (formikRef.current.values);
                    if (dmp != undefined && dmp == 'bond_logo') {
                        currValues.bond_logo = response.filename;
                    } else if (dmp !== undefined && dmp == 'bond_issuer_documents') {
                        currValues.bond_issuer_details[0].bond_issuer_documents = response.filename;
                    }
                    setFormikInitialData(e => ({ ...e, currValues }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        });
    }


    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
    }

    const handleClose_modal = (e: any) => {
        setOpenModal(e);
        suspended_list();
    }

    const activate_user = (id: any) => {
        setOpenModal(true);
        setUserId(id);
    }

    const view_more_content = (e: any) => {
        setViewContent(e)
        setOpen(true)
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerInputFeildStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    const addBonCallDetail = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = deepCopyIPO(formikRef.current.values);
            currValues.bond_call_details.push({ bond_call_detail_id: 0, bond_call_detail_datetime: '', bond_call_detail_amount: '' });
            setFormikInitialData(currValues);
        }
    }

    const removeBonCallDetail = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_call_details.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_call_details: paramValues });
        }
    }

    const addInterestCalYear = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = deepCopyIPO(formikRef.current.values);
            currValues.bond_cashflow_details.push({ bond_cashflow_date: '', bond_cashflow_amount: 0, bond_cashflow_days: '', bond_cashflow_type: 0 });
            setFormikInitialData(currValues);
        }
    }

    const removeInterestCalYear = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_cashflow_details.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_cashflow_details: paramValues });
        }
    }

    const addBondPutDetail = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = deepCopyIPO(formikRef.current.values);
            currValues.bond_put_details.push({ bond_put_detail_id: 0, bond_put_detail_datetime: '', bond_put_detail_amount: '' });
            setFormikInitialData(currValues);
        }
    }

    const removeBondPutDetail = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_put_details.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_put_details: paramValues });
        }
    }

    const addBondRating = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = deepCopyIPO(formikRef.current.values);
            if (currValues.bond_rating_details.filter((e) => e.bond_rating_agency == '' || e.bond_rating == 0 || e.bond_rating_certificate == '').length == 0) {
                currValues.bond_rating_details.push({ bond_rating_agency: '', bond_rating: 0, bond_rating_certificate: '' });
            } else {
                toast.error('Please fill rating feild');
            }
            setFormikInitialData(currValues);
        }
    }

    const removeBondRating = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_rating_details.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_rating_details: paramValues });
        }
    }

    const deepCopyIPO = (IPO: APIData.BondAdd) => {
        let returnIPOAdd: APIData.BondAdd = { ...IPO };
        if (IPO.bond_call_details !== undefined) {
            returnIPOAdd.bond_call_details = [...IPO.bond_call_details];
        } else {
            returnIPOAdd.bond_call_details = [];
        }
        if (IPO.bond_put_details !== undefined) {
            returnIPOAdd.bond_put_details = [...IPO.bond_put_details];
        } else {
            returnIPOAdd.bond_put_details = [];
        }
        if (IPO.bond_brand_tags !== undefined) {
            returnIPOAdd.bond_brand_tags = [...IPO.bond_brand_tags];
        } else {
            returnIPOAdd.bond_brand_tags = [];
        }
        if (IPO.bond_ncd_series !== undefined) {
            returnIPOAdd.bond_ncd_series = [...IPO.bond_ncd_series];
        } else {
            returnIPOAdd.bond_ncd_series = [];
        }
        if (IPO.bond_cashflow_details !== undefined) {
            returnIPOAdd.bond_cashflow_details = [...IPO.bond_cashflow_details];
        } else {
            returnIPOAdd.bond_cashflow_details = [];
        }
        return returnIPOAdd;
    }

    const onUpdateNCDAvailable = (checked: boolean, type: string) => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = ({ ...formikRef.current.values });
            if (type == 'bond_ncd_frequency_of_interest_payment') {
                currValues.bond_ncd_available[0].bond_ncd_frequency_of_interest_payment = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_minimum_application') {
                currValues.bond_ncd_available[0].bond_ncd_minimum_application = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_in_multiple_terms_of_thereafter') {
                currValues.bond_ncd_available[0].bond_ncd_in_multiple_terms_of_thereafter = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_issue_price_of_ncd') {
                currValues.bond_ncd_available[0].bond_ncd_issue_price_of_ncd = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_tenor') {
                currValues.bond_ncd_available[0].bond_ncd_tenor = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_coupon') {
                currValues.bond_ncd_available[0].bond_ncd_coupon = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_effective_yield') {
                currValues.bond_ncd_available[0].bond_ncd_effective_yield = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_mode_of_interest_payment') {
                currValues.bond_ncd_available[0].bond_ncd_mode_of_interest_payment = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_redemption_amount') {
                currValues.bond_ncd_available[0].bond_ncd_redemption_amount = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_put_and_option') {
                currValues.bond_ncd_available[0].bond_ncd_put_and_option = checked == true ? 1 : 0;
            }
        }
        setFormikInitialData(({ ...formikInitialData }));
    }

    const onClearNCDSeries = () => {
        setFormikInitialData(({ ...formikInitialData, bond_ncd_series: [] }));
    }

    const updateNDCSeriesList = () => {
        let initial_data = JSON.stringify(InitialData.BondNcdSeries);
        console.log(initial_data);
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = ({ ...formikRef.current.values });
            if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_frequency_of_interest_payment == '' || e.bond_ncd_frequency_of_interest_payment == null).length != 0) {
                toast.error('Please fill ncd frequency of interest payment')
            } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_tenor == 0).length != 0) {
                toast.error('Please fill tenor')
            } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_coupon == '' || e.bond_ncd_coupon == null).length != 0) {
                toast.error('Please fill ncd coupon')
            } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_effective_yield == '' || e.bond_ncd_effective_yield == null).length != 0) {
                toast.error('Please fill ncd effective yeild')
            } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_redemption_amount == '' || e.bond_ncd_redemption_amount == null).length != 0) {
                toast.error('Please fill ncd redemption amount')
            } else {
                currValues.bond_ncd_series.push(JSON.parse(initial_data));
            }
        }
        setFormikInitialData(({ ...formikInitialData }));
    }
    const handleRemoveNCDseries = (removeIndex: number) => {
        if (formikRef.current?.values) {
            var currValues: APIData.BondAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_ncd_series.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData(e => ({ ...e, bond_ncd_series: paramValues }));
        }
    }

    const yupSchema = Yup.object().shape({
        bond_issuer_name: Yup.string().required('Issuer Name is required'),
        bond_isin_number: Yup.string().required('ISIN Number is required'),
        bond_category_institutional: Yup.number().max(99, 'Institutional cannot be more than 2 digits').typeError('you must specify a number'),
        bond_category_non_institutional: Yup.number().max(99, 'Non Institutional cannot be more than 2 digits').typeError('you must specify a number'),
        bond_category_hni: Yup.number().max(99, 'HNI cannot be more than 2 digits').typeError('you must specify a number'),
        bond_category_retail: Yup.number().max(99, 'Retail cannot be more than 2 digits').typeError('you must specify a number'),
    });

    const submitData = (data: any, setSubmitting: any) => {
        console.log(data);
        if (location.state.mode == 'edit') {
            setShowPreloader(true);
            ServerAPI.putBond(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.BOND_DETAIL, { state: res.bond_isin_number });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            setShowPreloader(true);
            ServerAPI.postBond(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.BOND_DETAIL, { state: res.bond_isin_number });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }


    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Master </a></li>
                                                <li className="breadcrumb-item active ">Bond Add</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    // var ncd: APIData.BondNcdAvailable[] = [];
                                                    // values.bond_ncd_available != undefined && values.bond_ncd_available.map((flow: APIData.BondNcdAvailable) => {
                                                    //     ncd.push({
                                                    //         bond_ncd_id: 0,
                                                    //         bond_ncd_minimum_application: flow.bond_ncd_minimum_application,
                                                    //         bond_ncd_frequency_of_interest_payment: flow.bond_ncd_frequency_of_interest_payment,
                                                    //         bond_ncd_in_multiple_terms_of_thereafter: flow.bond_ncd_in_multiple_terms_of_thereafter,
                                                    //         bond_ncd_issue_price_of_ncd: flow.bond_ncd_issue_price_of_ncd,
                                                    //         bond_ncd_tenor: flow.bond_ncd_tenor,
                                                    //         bond_ncd_coupon: flow.bond_ncd_coupon,
                                                    //         bond_ncd_effective_yield: flow.bond_ncd_effective_yield,
                                                    //         bond_ncd_mode_of_interest_payment: flow.bond_ncd_mode_of_interest_payment,
                                                    //         bond_ncd_redemption_amount: flow.bond_ncd_redemption_amount,
                                                    //         bond_ncd_put_and_option: flow.bond_ncd_put_and_option
                                                    //     })
                                                    // })
                                                    // values.bond_ncd_available = ncd;
                                                    values.bond_id = location.state.mode == 'edit' ? bondID : 0;
                                                    if (values.bond_maturity_date == moment().format('YYYY-MM-DD')) {
                                                        toast.error('Please select the date greater than today for maturity date');
                                                        setSubmitting(true);
                                                    } else {
                                                        submitData(values, setSubmitting);
                                                    }
                                                }}
                                            >{(formikProps) => {
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> {location.state?.mode != undefined && location.state.mode == 'edit' ? 'Edit' : 'Add'} New Bond </h5>
                                                                        <div className="form-check form-switch mx-2">
                                                                            <label className="form-check-label" htmlFor="distributorSubTypeChecked">Disable/Enable</label>
                                                                            <input className="form-check-input " type="checkbox" role="switch" id="distributorSubTypeChecked"
                                                                                onChange={(e) => { setFormikInitialData(({ ...formikInitialData, bond_status: e.target.checked == true ? 1 : 0 })) }}
                                                                                checked={formikProps.values?.bond_status == BondIPOStatus.Active ? true : false} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_issuer_name" className='bond-label'>Issuer Name</label>
                                                                                    <input id="bond_issuer_name" name="bond_issuer_name" type="text" className='bond-input' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_issuer_name: e.target.value }))} value={formikProps.values?.bond_issuer_name} />
                                                                                    {formikProps.errors.bond_issuer_name ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_issuer_name}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_name" className='bond-label'>Bond Name</label>
                                                                                    <input id="bond_name" name="bond_name" type="text" className='bond-input' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_name: e.target.value }))} value={formikProps.values?.bond_name} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_nature_of_instrument" className='bond-label'>Nature of Instrument</label>
                                                                                    <select id="bond_nature_of_instrument" name="bond_nature_of_instrument"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_nature_of_instrument: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_nature_of_instrument}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Taxable</option>
                                                                                        <option value={2}>Tax Free</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_isin_number" className='bond-label'>ISIN Number</label>
                                                                                    <input id="bond_isin_number" name="bond_isin_number" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_isin_number: e.target.value }))}
                                                                                        value={formikProps.values?.bond_isin_number} />
                                                                                    {formikProps.errors.bond_isin_number ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_isin_number}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_yeild" className='bond-label'>Yeild (%)</label>
                                                                                    <input id="bonds_yeild" name="bonds_yeild" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bonds_yeild: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bonds_yeild} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_govt_guranatee" className='bond-label'>Govt. Guarantee</label>
                                                                                    <select id="bond_govt_guranateet" name="bond_govt_guranatee"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_govt_guranatee: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_govt_guranatee}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Yes</option>
                                                                                        <option value={2}>NO</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_face_value" className='bond-label'>Face Value</label>
                                                                                    <input id="bond_face_value" name="bond_face_value" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_face_value: e.target.value }))}
                                                                                        value={formikProps.values?.bond_face_value} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_issue_price" className='bond-label'>issue Price</label>
                                                                                    <input id="bond_issue_price" name="lastName" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_issue_price: e.target.value }))}
                                                                                        value={formikProps.values?.bond_issue_price} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_price_per_bond" className='bond-label'>Price per Bond</label>
                                                                                    <input id="bond_price_per_bond" name="bond_price_per_bond" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_price_per_bond: e.target.value }))}
                                                                                        value={formikProps.values?.bond_price_per_bond} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="issue_date" className='bond-label'>Issue Date</label>
                                                                                    {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={issueDate}
                                                                                        onChange={handleChangeIssueDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_issue_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_issue_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="From Date"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_interest_frequency" className='bond-label'>Interest Frequency</label>
                                                                                    <select id="bond_interest_frequency" name="bond_interest_frequency"
                                                                                        value={formikProps.values.bond_interest_frequency}
                                                                                        onChange={e => setFormikInitialData(value => ({ ...value, bond_interest_frequency: e.target.value }))}
                                                                                        className='bond-input-select '
                                                                                    >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="1">Monthly</option>
                                                                                        <option value="2">Quarterly</option>
                                                                                        <option value="3">Half Yearly</option>
                                                                                        <option value="4">Yearly</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Calc Int on Holiday</label>
                                                                                    <select id="Nature of Instrument" name="bond_calc_int_on_holidays"
                                                                                        value={formikProps.values.bond_calc_int_on_holidays}
                                                                                        onChange={e => setFormikInitialData(value => ({ ...value, bond_calc_int_on_holidays: parseInt(e.target.value) }))}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="1">Yes</option>
                                                                                        <option value="2">No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_int_on_maturity" className='bond-label'>Calc Int on Maturity</label>
                                                                                    <select id="bond_int_on_maturity" name="bond_int_on_maturity"
                                                                                        value={formikProps.values.bond_int_on_maturity}
                                                                                        onChange={e => setFormikInitialData(value => ({ ...value, bond_int_on_maturity: parseInt(e.target.value) }))}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="1">Yes</option>
                                                                                        <option value="2">No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Exchange</label>
                                                                                    <select id="Nature of Instrument" name="bond_exchange"
                                                                                        value={formikProps.values.bond_exchange}
                                                                                        onChange={e => setFormikInitialData(value => ({ ...value, bond_exchange: e.target.value }))}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="1">BSE</option>
                                                                                        <option value="2">NSE</option>
                                                                                        <option value="3">Both BSE & NSE</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Listing</label>
                                                                                    <select id="Nature of Instrument" name="bond_listing"
                                                                                        value={formikProps.values.bond_listing}
                                                                                        onChange={e => setFormikInitialData(value => ({ ...value, bond_listing: parseInt(e.target.value) }))}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>BSE</option>
                                                                                        <option value={2}>NSE</option>
                                                                                        <option value={3}>Both BSE & NSE</option>
                                                                                        <option value={4}>UNLISTED</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_minimum_application" className='bond-label'>Minimum Application</label>
                                                                                    <input id="bond_minimum_application" name="bond_minimum_application" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_minimum_application: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_minimum_application} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_issue_size" className='bond-label'>Issue Size</label>
                                                                                    <input id="bond_issue_size" name="bond_issue_size" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_issue_size: e.target.value }))}
                                                                                        value={formikProps.values?.bond_issue_size} />
                                                                                </div>
                                                                            </Grid>
                                                                            {/* <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_yeild" className='bond-label'>Yeild (%)</label>
                                                                                    <input id="bonds_yeild" name="bonds_yeild" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bonds_yeild: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bonds_yeild} />
                                                                                </div>
                                                                            </Grid> */}
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_logo" className='bond-label'>Upload Bond Logo</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_logo' })) } })}>
                                                                                        <input {...getInputProps()} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_logo, 'Upload bond_logo')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_about_this_ipo" className='bond-label'>About this</label>
                                                                                    <input id="bond_about_this_ipo" name="bond_about_this_ipo" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_about_this_ipo: e.target.value }))}
                                                                                        value={formikProps.values?.bond_about_this_ipo} />
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_security_type" className='bond-label'>Security Type</label>
                                                                                    <select id="bond_security_type" name="bond_security_type" disabled
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_security_type: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_security_type}
                                                                                        className='bond-input-select bond-input-disabled'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Private Sector Bond (Non Convertible Debentures)</option>
                                                                                        <option value={2}>PSU Bond/ Government Security</option>
                                                                                        <option value={3}>Fixed Deposit</option>
                                                                                        <option value={4}>54 EC Capital Gains Bonds</option>
                                                                                        <option value={5}>RBI Sovereign Gold Bond</option>
                                                                                        <option value={6}>Floating Rate Savings Bonds</option>
                                                                                        <option value={7}> Corporate Fixed Deposit   </option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_st" className='bond-label'>ST</label>
                                                                                    <input id="bond_st" name="bond_st" type="text" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_st: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_st} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_security_type" className='bond-label'>Security Type Code</label>
                                                                                    <input id="bond_security_type" name="bond_security_type" type="text" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_security_type: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_security_type} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_security_code" className='bond-label'>Security Code</label>
                                                                                    <input id="bond_security_code" name="bond_security_code" type="text" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_security_code: e.target.value }))}
                                                                                        value={formikProps.values?.bond_security_code} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_guaranted_by" className='bond-label'>Guaranted By</label>
                                                                                    <input id="bond_guaranted_by" name="bond_guaranted_by" type="text" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_guaranted_by: e.target.value }))}
                                                                                        value={formikProps.values?.bond_guaranted_by} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_interest_type" className='bond-label'>Interest Type</label>
                                                                                    <select id="bond_interest_type" name="bond_interest_type" disabled
                                                                                        value={formikProps.values.bond_interest_type}
                                                                                        onChange={e => setFormikInitialData(value => ({ ...value, bond_interest_type: parseInt(e.target.value) }))}
                                                                                        className='bond-input-select bond-input-disabled'
                                                                                    >
                                                                                        <option value="">Select</option>
                                                                                        <option value="Food">Interest Type 1</option>
                                                                                        <option value="Entertainment">Interest Type 2</option>
                                                                                        <option value="Academic">Interest Type 3</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_on" className='bond-label'>Coupon on</label>
                                                                                    <input id="bond_coupon_on" name="bond_coupon_on" type="text" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_coupon_on: e.target.value }))}
                                                                                        value={formikProps.values?.bond_coupon_on} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_on" className='bond-label'>Interest Days</label>
                                                                                    <input id="bond_coupon_on" name="bond_coupon_on" type="text" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_interest_days: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_interest_days} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Book Closer Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_closer_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_closer_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="From Date"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>DMAT Book Closer Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_dmat_book_closer_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_dmat_book_closer_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="From Date"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_rbi_loan_code" className='bond-label'>RBI Loan Code</label>
                                                                                    <input id="bond_rbi_loan_code" name="bond_rbi_loan_code" type="text" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_rbi_loan_code: e.target.value }))}
                                                                                        value={formikProps.values?.bond_rbi_loan_code} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="ip_date" className='bond-label'>First IP Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_ip_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_ip_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="From Date"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Category Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>I - Institutional</label>
                                                                                    <input id="bond_category_institutional" name="bond_category_institutional" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_institutional: e.target.value }))}
                                                                                        value={formikProps.values?.bond_category_institutional}
                                                                                        onBlur={formikProps.handleBlur}
                                                                                    />
                                                                                    {formikProps.touched.bond_category_institutional && formikProps.errors.bond_category_institutional ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_institutional).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>II - Non-Institutional</label>
                                                                                    <input id="bond_category_non_institutional" name="bond_category_non_institutional" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_non_institutional: e.target.value }))}
                                                                                        value={formikProps.values?.bond_category_non_institutional}
                                                                                        onBlur={formikProps.handleBlur}
                                                                                    />
                                                                                    {formikProps.touched.bond_category_non_institutional && formikProps.errors.bond_category_non_institutional ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_non_institutional).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>III - HNI</label>
                                                                                    <input id="bond_category_hni" name="bond_category_hni" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_hni: e.target.value }))}
                                                                                        value={formikProps.values?.bond_category_hni}
                                                                                        onBlur={formikProps.handleBlur}
                                                                                    />
                                                                                    {formikProps.touched.bond_category_hni && formikProps.errors.bond_category_hni ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_hni).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>IV - Retail</label>
                                                                                    <input id="bond_category_retail" name="bond_category_retail" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_retail: e.target.value }))}
                                                                                        value={formikProps.values?.bond_category_retail}
                                                                                        onBlur={formikProps.handleBlur}
                                                                                    />
                                                                                    {formikProps.touched.bond_category_retail && formikProps.errors.bond_category_retail ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_retail).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Maturity Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                {/* <div className='d-grid'>
                                                                                    <label htmlFor="bond_maturity_date" className='bond-label'>Maturity Date</label>
                                                                                    <input id="bond_maturity_date" name="bond_maturity_date" type="text" className='bond-input'
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_maturity_date: e.target.value })) }}
                                                                                        value={formikProps.values?.bond_maturity_date!} />
                                                                                </div> */}
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_maturity_date" className='bond-label'>Maturity Date</label>
                                                                                    {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={maturityDate}
                                                                                        onChange={handleChangeMaturityDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_maturity_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_maturity_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="From Date"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_maturity_amount" className='bond-label'>Maturity Amount</label>
                                                                                    <input id="bond_maturity_amount" name="bond_maturity_amount" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_maturity_amount: e.target.value }))}
                                                                                        value={formikProps.values?.bond_maturity_amount} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Coupon Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                {/* <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_date" className='bond-label'>Coupon Date</label>
                                                                                    <input id="bond_coupon_date" name="bond_coupon_date" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_coupon_date: e.target.value }))}
                                                                                        value={formikProps.values?.bond_coupon_date!} />
                                                                                </div> */}
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_maturity_date" className='bond-label'>Coupon Date</label>
                                                                                    {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={couponDate}
                                                                                        onChange={handleChangeCouponDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_coupon_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_coupon_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="From Date"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_amount" className='bond-label'>Coupon Amount</label>
                                                                                    <input id="bond_coupon_amount" name="bond_coupon_amount" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_coupon_amount: e.target.value }))}
                                                                                        value={formikProps.values?.bond_coupon_amount} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Call Details </h5>
                                                                        <div className='bond-add-btn'>
                                                                            <a className='text-white'
                                                                                onClick={() => addBonCallDetail()}
                                                                            >
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.bond_call_details.map((call_detail, index) => {
                                                                                return (
                                                                                    <Grid direction="row" container spacing={2}>
                                                                                        <Grid item xs={3}>
                                                                                            {/* <div className='d-grid'>
                                                                                                <label htmlFor="bond_call_detail_datetime" className='bond-label'>Call Date</label>
                                                                                                <input id="bond_call_detail_datetime" name="bond_call_detail_datetime" type="text" className='bond-input'
                                                                                                    value={call_detail.bond_call_detail_datetime!}
                                                                                                    onChange={e => { call_detail.bond_call_detail_datetime = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div> */}
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_date" className='bond-label'>Interest Date</label>
                                                                                                {/* <DesktopDatePicker
                                                                                                    inputFormat="DD/MM/YYYY"
                                                                                                    value={call_detail.bond_call_detail_datetime}
                                                                                                    onChange={e => { call_detail.bond_call_detail_datetime = e; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    renderInput={(params: any) => <TextField {...params} />}
                                                                                                    className="date-picker-input-style"
                                                                                                    components={{
                                                                                                        OpenPickerIcon: DateIcon
                                                                                                    }}
                                                                                                /> */}
                                                                                                <DatePicker
                                                                                                    selected={localStringToIST(call_detail.bond_call_detail_datetime)}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    closeOnScroll={true}
                                                                                                    onChange={(date: Date) => { call_detail.bond_call_detail_datetime = moment(date).format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                    placeholderText="From Date"
                                                                                                    className='date-picker-style'
                                                                                                    peekNextMonth
                                                                                                    showMonthDropdown
                                                                                                    showYearDropdown
                                                                                                    dropdownMode="select"
                                                                                                    customInput={(<CustomInput />)}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={3}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_call_detail_amount" className='bond-label'>Call Amount</label>
                                                                                                <input id="bond_call_detail_amount" name="bond_call_detail_amount" type="number" className='bond-input'
                                                                                                    value={call_detail.bond_call_detail_amount}
                                                                                                    onChange={e => { call_detail.bond_call_detail_amount = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='bond-add-btn-minus'>
                                                                                                <a onClick={() => removeBonCallDetail(index)}><span>-</span></a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Put Details </h5>
                                                                        <div className='bond-add-btn'>
                                                                            <a className='text-white'
                                                                                onClick={() => addBondPutDetail()}
                                                                            >
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.bond_put_details.map((put_detail, index) => {
                                                                                return (
                                                                                    <Grid direction="row" container spacing={2}>
                                                                                        <Grid item xs={3}>
                                                                                            {/* <div className='d-grid'>
                                                                                                <label htmlFor="bond_put_detail_datetime" className='bond-label'>Put Date</label>
                                                                                                <input id="bond_put_detail_datetime" name="bond_put_detail_datetime" type="text" className='bond-input'
                                                                                                    value={put_detail.bond_put_detail_datetime!}
                                                                                                    onChange={e => { put_detail.bond_put_detail_datetime = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div> */}
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_date" className='bond-label'>Put Date</label>
                                                                                                {/* <DesktopDatePicker
                                                                                                    inputFormat="DD/MM/YYYY"
                                                                                                    value={put_detail.bond_put_detail_datetime}
                                                                                                    onChange={e => { put_detail.bond_put_detail_datetime = e; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    renderInput={(params: any) => <TextField {...params} />}
                                                                                                    className="date-picker-input-style"
                                                                                                    components={{
                                                                                                        OpenPickerIcon: DateIcon
                                                                                                    }}
                                                                                                /> */}
                                                                                                <DatePicker
                                                                                                    selected={localStringToIST(put_detail.bond_put_detail_datetime)}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    closeOnScroll={true}
                                                                                                    onChange={(date: Date) => { put_detail.bond_put_detail_datetime = moment(date).format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                    placeholderText="From Date"
                                                                                                    className='date-picker-style'
                                                                                                    peekNextMonth
                                                                                                    showMonthDropdown
                                                                                                    showYearDropdown
                                                                                                    dropdownMode="select"
                                                                                                    customInput={(<CustomInput />)}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={3}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_put_detail_amount" className='bond-label'>Put Amount</label>
                                                                                                <input id="bond_put_detail_amount" name="bond_put_detail_amount" type="number" className='bond-input'
                                                                                                    value={put_detail.bond_put_detail_amount}
                                                                                                    onChange={e => { put_detail.bond_put_detail_amount = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='bond-add-btn-minus'>
                                                                                                <a onClick={() => removeBondPutDetail(index)}><span>-</span></a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Interest Calculation </h5>
                                                                        <div className='bond-add-btn'>
                                                                            <a className='text-white'
                                                                                onClick={() => addInterestCalYear()}
                                                                            >
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.bond_cashflow_details != undefined && formikProps.values?.bond_cashflow_details.map((int, index) => {
                                                                                return (
                                                                                    <Grid direction="row" container spacing={2}>
                                                                                        <Grid item xs={2.5}>
                                                                                            {/* <div className='d-grid'>
                                                                                                <label htmlFor="bond_put_detail_datetime" className='bond-label'>Interest Date</label>
                                                                                                <input id="bond_put_detail_datetime" name="bond_put_detail_datetime" type="text" className='bond-input'
                                                                                                    value={int.bond_cashflow_date!}
                                                                                                    onChange={e => { int.bond_cashflow_date = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div> */}
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_date" className='bond-label'>Interest Date</label>
                                                                                                {/* <DesktopDatePicker
                                                                                                    inputFormat="DD/MM/YYYY"
                                                                                                    value={int.bond_cashflow_date}
                                                                                                    onChange={e => {
                                                                                                        int.bond_cashflow_date = e;
                                                                                                        setFormikInitialData(({ ...formikInitialData }));
                                                                                                        setinterestDateCal(e ? e?.format('MM/DD/YYYY').toString() : '');
                                                                                                        const res = handleCalculateDay(e ? e?.format('MM/DD/YYYY').toString() : '', index);
                                                                                                        console.log(res)
                                                                                                        if (res) {
                                                                                                            // setFormikInitialData(selDate=>({ ...selDate,bond_cashflow_details: [...selDate.bond_cashflow_details,selDate.bond_cashflow_details[index].bond_cashflow_days = res+'' ] }));
                                                                                                            int.bond_cashflow_days = res + '';
                                                                                                        }
                                                                                                    }}
                                                                                                    renderInput={(params: any) => <TextField {...params} />}
                                                                                                    className="date-picker-input-style"
                                                                                                    components={{
                                                                                                        OpenPickerIcon: DateIcon
                                                                                                    }}
                                                                                                /> */}
                                                                                                <DatePicker
                                                                                                    selected={localStringToIST(int.bond_cashflow_date)}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    closeOnScroll={true}
                                                                                                    onChange={(date: Date) => {
                                                                                                        int.bond_cashflow_date = moment(date).format('YYYY-MM-DD').toString();
                                                                                                        setFormikInitialData(({ ...formikInitialData }));
                                                                                                        const res = handleCalculateDay(date ? moment(date).format('MM/DD/YYYY').toString() : '', index);
                                                                                                        console.log(res)
                                                                                                        if (res) {
                                                                                                            int.bond_cashflow_days = res + '';
                                                                                                        }
                                                                                                    }}
                                                                                                    placeholderText="From Date"
                                                                                                    className='date-picker-style'
                                                                                                    peekNextMonth
                                                                                                    showMonthDropdown
                                                                                                    showYearDropdown
                                                                                                    dropdownMode="select"
                                                                                                    customInput={(<CustomInput />)}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={2.5}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_type" className='bond-label'>Interest Rate</label>
                                                                                                <input id="bond_cashflow_type" name="bond_cashflow_type" type="number" className='bond-input'
                                                                                                    value={int.bond_cashflow_type}
                                                                                                    onChange={e => { int.bond_cashflow_type = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={2.5}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_amount" className='bond-label'>Interest Amount</label>
                                                                                                <input id="bond_cashflow_amount" name="bond_cashflow_amount" type="text" className='bond-input'
                                                                                                    value={int.bond_cashflow_amount!}
                                                                                                    onChange={e => { int.bond_cashflow_amount = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={2.5}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_days" className='bond-label'>No. of Days</label>
                                                                                                <input id="bond_cashflow_days" name="bond_cashflow_days" type="number" className='bond-input'
                                                                                                    value={int.bond_cashflow_days}
                                                                                                    onChange={e => { int.bond_cashflow_days = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='bond-add-btn-minus'>
                                                                                                <a onClick={() => removeInterestCalYear(index)}><span>-</span></a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Bond's Rating Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.bond_rating_details.map((rating, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid direction="row" container spacing={2}>
                                                                                            <Grid item xs={3.5}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_crisil_short_rating" className='bond-label'>Rating Agency Name</label>
                                                                                                    <input id="bond_crisil_short_rating" name="bond_crisil_short_rating" type="text" className='bond-input'
                                                                                                        value={rating.bond_rating_agency}
                                                                                                        onChange={e => { rating.bond_rating_agency = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3.5}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_crisil_short_rating" className='bond-label'>Choose Rating</label>
                                                                                                    <select id="Nature of Instrument" name="email"
                                                                                                        // onChange={formikProps.handleChange}
                                                                                                        // value={formikProps.values.email}
                                                                                                        className='bond-input-select'
                                                                                                    >
                                                                                                        <option value={0}>A</option>
                                                                                                        <option value={1}>AA</option>
                                                                                                        <option value={2}>AAA</option>
                                                                                                        <option value={3}>BBB</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3.5}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_rating_certificate" className='bond-label'>Upload Rating Certificate</label>
                                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild({ feild_type: 'bond_rating_certificate', inx: index }) } })}>
                                                                                                        <input {...getInputProps()} />
                                                                                                        {
                                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_rating_details[index].bond_rating_certificate, 'Upload Rating Ceritificate')}</label>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='bond-add-btn-minus'>
                                                                                                    <a><span>-</span></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Bond's Rating Details </h5>
                                                                        <div className='bond-add-btn' onClick={() => addBondRating()}>
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.bond_rating_details.map((rating, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid direction="row" container spacing={2}>
                                                                                            <Grid item xs={3.5}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_crisil_short_rating" className='bond-label'>Rating Agency Name</label>
                                                                                                    <input id="bond_crisil_short_rating" name="bond_crisil_short_rating" type="text" className='bond-input'
                                                                                                        value={rating.bond_rating_agency}
                                                                                                        onChange={e => { rating.bond_rating_agency = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3.5}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_crisil_short_rating" className='bond-label'>Choose Rating</label>
                                                                                                    <select id="bond_id" name="bond_id"
                                                                                                        value={rating.bond_rating}
                                                                                                        onChange={e => { rating.bond_rating = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                        className='bond-input-select'
                                                                                                    >
                                                                                                        {bondRatingOptions !== undefined && !bondRatingOptions.some(bondRatingOption => bondRatingOption.value === "0") && (
                                                                                                            <option value="0">Select</option>
                                                                                                        )}
                                                                                                        {bondRatingOptions && bondRatingOptions.map((option, index) => {
                                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                                        })}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_rating_certificate" className='bond-label'>Upload Rating Certificate</label>
                                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild({ feild_type: 'bond_rating_certificate', inx: index }) } })}>
                                                                                                        <input {...getInputProps()} />
                                                                                                        {
                                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_rating_details[index]?.bond_rating_certificate, 'Upload Rating Ceritificate')}</label>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='bond-add-btn-minus'>
                                                                                                    <a onClick={() => removeBondRating(index)} style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Brand Tags </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-center">
                                                                            <div className="row row-cols-12">
                                                                                {
                                                                                    formikProps.values?.bond_brand_tags != undefined && formikProps.values?.bond_brand_tags.length > 0 ?
                                                                                        <>
                                                                                            {
                                                                                                formikProps.values?.bond_brand_tags.map((brand_tags, index) => {
                                                                                                    return (
                                                                                                        <div className={`col-3 brand-tag${brand_tags.bond_brand_tag_id != null ? '-active' : ''} px-1 py-2 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            onClick={() =>
                                                                                                                updateBrandTags(brand_tags.bond_brand_tag_id != null ? false : true, index)
                                                                                                            }
                                                                                                        >{brand_tags.brand_tag_name}</div>
                                                                                                    )
                                                                                                })

                                                                                            }
                                                                                        </>
                                                                                        : ''
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Eligible Investors </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-center">
                                                                            {
                                                                                formikProps.values?.bond_eligible_investors != undefined && formikProps.values?.bond_eligible_investors.length > 0 ?
                                                                                    <div className="row row-cols-12 ">
                                                                                        {
                                                                                            formikProps.values?.bond_eligible_investors.map((eligible, index) => {
                                                                                                return (
                                                                                                    <div className={`col-3 brand-tag${eligible.bond_eligible_investor_id != null ? '-active' : ''} px-1 py-2 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        onClick={() =>
                                                                                                            updateEligibleTags(eligible.bond_eligible_investor_id != null ? false : true, index)
                                                                                                        }
                                                                                                    >{eligible.eligible_investor_name}</div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Add Key Highlights </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Editor
                                                                            editorState={addKeyHeighlights}
                                                                            toolbarClassName="toolbar_form-control"
                                                                            wrapperClassName="editor_form-control_feedDetail"
                                                                            editorClassName="editor_textFeild_form-control"
                                                                            onEditorStateChange={(e: any) => onKeyHeighlightsEditorStateChange(e)}
                                                                            placeholder="Write a Comment..."
                                                                            toolbar={wysiwygToolbar}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Add Issuer Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikRef.current?.values.bond_issuer_details.map((issuer_details, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid direction="row" container spacing={2}>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Issuer's Type</label>
                                                                                                    {/* <input id="Nature of Instrument" name="email" type="email" className='bond-input' onChange={formikProps.handleChange} value={formikProps.values.email} /> */}
                                                                                                    <select id="Nature of Instrument" name="email"
                                                                                                        // onChange={formikProps.handleChange}
                                                                                                        // value={''}
                                                                                                        className='bond-input-select'
                                                                                                    >
                                                                                                        <option value="">Select</option>
                                                                                                        <option value="1">Private Sector Corporate</option>
                                                                                                        <option value="2">Public Sector Undertaking</option>
                                                                                                        <option value="3">Private  Bank</option>
                                                                                                        <option value="4">Public Sector Bank</option>
                                                                                                        <option value="5">State Government</option>
                                                                                                        <option value="6">Central Government</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuer_total_annual_revenue" className='bond-label'>Total Annual Revenue</label>
                                                                                                    <input id="bond_issuer_total_annual_revenue" name="bond_issuer_total_annual_revenue" type="text" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_total_annual_revenue}
                                                                                                        onChange={e => { issuer_details.bond_issuer_total_annual_revenue = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                {/* <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuer_year_of_inception" className='bond-label'>Year of Inception</label>
                                                                                                    <input id="bond_issuer_year_of_inception" name="bond_issuer_year_of_inception" type="text" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_year_of_inception}
                                                                                                        onChange={e => { issuer_details.bond_issuer_year_of_inception = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div> */}
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_cashflow_date" className='bond-label'>Year of Inception</label>
                                                                                                    {/* <DatePicker
                                                                                                        selected={localStringToIST(issuer_details.bond_issuer_year_of_inception)}
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        closeOnScroll={true}
                                                                                                        onChange={(date: Date) => { issuer_details.bond_issuer_year_of_inception = moment(date).format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        placeholderText="From Date"
                                                                                                        className='date-picker-style'
                                                                                                        peekNextMonth
                                                                                                        showMonthDropdown
                                                                                                        showYearDropdown
                                                                                                        dropdownMode="select"
                                                                                                        customInput={(<CustomInput />)}
                                                                                                    /> */}
                                                                                                    <input id="bond_year_of_inception" name="bond_year_of_inception" type="number" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_year_of_inception}
                                                                                                        onChange={e => { issuer_details.bond_issuer_year_of_inception = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuer_industry" className='bond-label'>Industry</label>
                                                                                                    <input id="bond_issuer_industry" name="bond_issuer_industry" type="text" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_industry}
                                                                                                        onChange={e => { issuer_details.bond_issuer_industry = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuer_head_office" className='bond-label'>Head Office</label>
                                                                                                    <input id="bond_issuer_head_office" name="bond_issuer_head_office" type="text" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_head_office}
                                                                                                        onChange={e => { issuer_details.bond_issuer_head_office = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Type of Issuer</label>
                                                                                                    {/* <input id="Nature of Instrument" name="email" type="email" className='bond-input' onChange={formikProps.handleChange} value={formikProps.values.email} /> */}
                                                                                                    <select id="Nature of Instrument" name="email"
                                                                                                        // onChange={formikProps.handleChange}
                                                                                                        // value={''}
                                                                                                        className='bond-input-select'
                                                                                                    >
                                                                                                        <option value="">Select</option>
                                                                                                        <option value="1">Private Sector Corporate</option>
                                                                                                        <option value="2">Public Sector Undertaking</option>
                                                                                                        <option value="3">Private  Bank</option>
                                                                                                        <option value="4">Public Sector Bank</option>
                                                                                                        <option value="5">State Government</option>
                                                                                                        <option value="6">Central Government</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuer_profile_link" className='bond-label'>Issuers Profile Link</label>
                                                                                                    <input id="bond_issuer_profile_link" name="bond_issuer_profile_link" type="text" className='bond-input-upload-link'
                                                                                                        value={issuer_details.bond_issuer_profile_link}
                                                                                                        onChange={e => { issuer_details.bond_issuer_profile_link = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuer_finance_details" className='bond-label'>Issuers Finance Details</label>
                                                                                                    <input id="bond_issuer_finance_details" name="bond_issuer_finance_details" type="text" className='bond-input-upload-link'
                                                                                                        value={issuer_details.bond_issuer_finance_details}
                                                                                                        onChange={e => { issuer_details.bond_issuer_finance_details = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuer_details" className='bond-label'>Upload ISSUE DOCUMENTS</label>
                                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_issuer_documents' })) } })}>
                                                                                                        <input {...getInputProps()} />
                                                                                                        {
                                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_issuer_details[index].bond_issuer_documents, 'Upload ISSUE DOCUMENTS')}</label>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <label htmlFor="bond_issue_documents" className='bond-label mt-2'>Paragraph</label>
                                                                                                <Editor
                                                                                                    editorState={addIssuerAbout}
                                                                                                    toolbarClassName="toolbar_form-control"
                                                                                                    wrapperClassName="editor_form-control_feedDetail"
                                                                                                    editorClassName="editor_textFeild_form-control"
                                                                                                    onEditorStateChange={(e: any) => onIssuerAboutEditorStateChange(e)}
                                                                                                    placeholder="Write a Comment..."
                                                                                                    toolbar={wysiwygToolbar}
                                                                                                    wrapperStyle={{ margin: "10px 0px" }}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                                        <div className='d-flex justify-content-start'>
                                                                            <h5 className="m-0 header"> Specific Terms for Each Series of NCDs Details </h5>
                                                                        </div>
                                                                        <div className='d-flex justify-content-end align-items-center '>
                                                                            <div className="form-check form-switch mx-2">
                                                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Disable/Enable</label>
                                                                                <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                            </div>
                                                                            <button className='bond-add-btn' onClick={() => updateNDCList()}>
                                                                                <a className='text-white'>
                                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                    Add
                                                                                </a>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            NDCList.length > 0 ?
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                                        <thead className="city-table">
                                                                                            <tr>
                                                                                                {
                                                                                                    NDCList[0].header.map((res: any, index: number) => {
                                                                                                        return (
                                                                                                            <th>{res.name}</th>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        </thead>
                                                                                        {
                                                                                            NDCList[0].content.map((res: any, i: any) => (
                                                                                                <tbody key={i}>
                                                                                                    <tr >
                                                                                                        <td>{i + 1}</td>
                                                                                                        <td>{res.name}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            ))
                                                                                        }
                                                                                    </table>
                                                                                </div>
                                                                                : <tbody>
                                                                                    <tr className='py-4'>
                                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                        }
                                                                    </div>
                                                                    <div className='row text-end my-3'>
                                                                        <div className=''>
                                                                            <Link to={PageLinks.USERSDETAILS} state={user_id} className='cancel-btn mx-3' >Clear</Link>
                                                                            <button type='submit' className='save-btn'>
                                                                                Save
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                                        <div className='d-flex justify-content-start'>
                                                                            <h5 className="m-0 header"> Specific Terms for Each Series of NCDs Details </h5>
                                                                        </div>
                                                                        <div className='d-flex justify-content-end align-items-center '>
                                                                            <div className="form-check form-switch mx-2">
                                                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Disable/Enable</label>
                                                                                <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                                                                //  onChange={(e) => { setFormikInitialData(({ ...formikInitialData, bond_ncd_status: e.target.checked == true ? 1 : 0 })) }}
                                                                                //  checked={formikProps.values?.bond_ncd_status == NCDStatus.Active ? true : false}
                                                                                />
                                                                            </div>
                                                                            <div className='bond-add-btn' onClick={() => updateNDCSeriesList()}>
                                                                                <a className='text-white'>
                                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                    Add
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="table-responsive " data-pattern="priority-columns">
                                                                            <table id="tech-companies-1" className="table table table-bordered">
                                                                                <thead className="city-table">
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        <th>Name</th>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series.map((res: APIData.BondNcdSeries, index: number) => {
                                                                                                return (
                                                                                                    <th>Series{index + 1}<span onClick={() => { handleRemoveNCDseries(index) }}><img src='../../assets/images/close-btn.svg' className='ncd-series-close-btn'></img></span></th>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_frequency_of_interest_payment"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_frequency_of_interest_payment") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0]?.bond_ncd_frequency_of_interest_payment == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Frequency of Interest Payment</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series != undefined && formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <select
                                                                                                            id={'bond_ncd_frequency_of_interest_payment' + inx}
                                                                                                            name="bond_ncd_frequency_of_interest_payment"
                                                                                                            style={{ width: "100%" }}
                                                                                                            onChange={e => { feq.bond_ncd_frequency_of_interest_payment = e.target.value; setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                            value={feq.bond_ncd_frequency_of_interest_payment}
                                                                                                            className='bond-input-select'
                                                                                                        >
                                                                                                            <option value={0}>Select</option>
                                                                                                            <option value={1}>Monthly</option>
                                                                                                            <option value={2}>Cumulative</option>
                                                                                                            <option value={3}>Yearly</option>
                                                                                                            <option value={4}>Maturity</option>
                                                                                                        </select>
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    {/* <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_minimum_application"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_minimum_application") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0].bond_ncd_minimum_application == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Minimum Application (₹)</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_minimum_application" + inx} name="bond_ncd_minimum_application" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_minimum_application}
                                                                                                            onChange={e =>
                                                                                                            // onUpdateNCDSeries(e.target.value, "bond_ncd_minimum_application", inx)
                                                                                                            { feq.bond_ncd_minimum_application = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })); }
                                                                                                            }
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr> */}
                                                                                    {/* <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_in_multiple_terms_of_thereafter"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_in_multiple_terms_of_thereafter") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0].bond_ncd_in_multiple_terms_of_thereafter == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>In Multiples of thereafter (₹)</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_in_multiple_terms_of_thereafter" + inx} name="bond_ncd_in_multiple_terms_of_thereafter" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_in_multiple_terms_of_thereafter}
                                                                                                            onChange={e => { feq.bond_ncd_in_multiple_terms_of_thereafter = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_issue_price_of_ncd"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_issue_price_of_ncd") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0].bond_ncd_issue_price_of_ncd == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Face Value/ Issue Price of NCDs (`/ NCD) (₹)</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_issue_price_of_ncd" + inx} name="bond_ncd_issue_price_of_ncd" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_issue_price_of_ncd}
                                                                                                            onChange={e => { feq.bond_ncd_issue_price_of_ncd = e.target.value; setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr> */}
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_tenor"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_tenor") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0]?.bond_ncd_tenor == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Tenor</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series != undefined && formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_tenor" + inx} name="bond_ncd_tenor" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_tenor}
                                                                                                            onChange={e => { feq.bond_ncd_tenor = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_coupon"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_coupon") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0]?.bond_ncd_coupon == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Coupon (% per annum)</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series != undefined && formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_coupon" + inx} name="bond_ncd_coupon" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_coupon}
                                                                                                            onChange={e => { feq.bond_ncd_coupon = e.target.value; setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_effective_yield"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_effective_yield") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0]?.bond_ncd_effective_yield == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Effective Yield (% per annum)</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series != undefined && formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_effective_yield" + inx} name="bond_ncd_effective_yield" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_effective_yield}
                                                                                                            onChange={e => { feq.bond_ncd_effective_yield = e.target.value; setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    {/* <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_mode_of_interest_payment"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_mode_of_interest_payment") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0].bond_ncd_mode_of_interest_payment == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Mode of Interest Payment</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_mode_of_interest_payment" + inx} name="bond_ncd_mode_of_interest_payment" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_mode_of_interest_payment}
                                                                                                            onChange={e => { feq.bond_ncd_mode_of_interest_payment = e.target.value; setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr> */}
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_redemption_amount"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_redemption_amount") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0]?.bond_ncd_redemption_amount == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Redemption Amount (₹ / NCD) on Maturity</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series != undefined && formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_redemption_amount" + inx} name="bond_ncd_redemption_amount" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_redemption_amount}
                                                                                                            onChange={e => { feq.bond_ncd_redemption_amount = e.target.value; setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    {/* <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_put_and_option"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_put_and_option") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0].bond_ncd_put_and_option == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Put and Call Option</td>
                                                                                        {
                                                                                            formikRef.current?.values.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_put_and_option" + inx} name="bond_ncd_put_and_option" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_put_and_option}
                                                                                                            onChange={e => { feq.bond_ncd_put_and_option = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr> */}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='row text-end my-3'>
                                                                        <div className=''>
                                                                            <a className='cancel-btn mx-3' onClick={() => onClearNCDSeries()} >Clear</a>
                                                                            <a className='save-btn'>
                                                                                Save
                                                                            </a>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Disclaimer </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Editor
                                                                            editorState={addComments}
                                                                            toolbarClassName="toolbar_form-control"
                                                                            wrapperClassName="editor_form-control_feedDetail"
                                                                            editorClassName="editor_textFeild_form-control"
                                                                            onEditorStateChange={(e: any) => onEditorStateChange(e)}
                                                                            placeholder="Write a Comment..."
                                                                            toolbar={wysiwygToolbar}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <Grid direction="row" container spacing={2}>
                                                                {/* <Grid item xs={4}>
                                                                    <div className='d-grid'>
                                                                        <label htmlFor="Bond Name" className='bond-label'>Upload ASBA Details</label>
                                                                        <div {...getRootProps({ onClick: event => { setUploadFeild({ feild_type: 'bond_asba_details' }) } })}>
                                                                            <input {...getInputProps()} />
                                                                            {
                                                                                <label className="bond-input-upload-file">
                                                                                    {getURLExtension(
                                                                                        // formikRef.current?.values.bond_asba_details 
                                                                                        ''
                                                                                        , 'Upload ASBA Details')}
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Grid> */}
                                                                <Grid item xs={4}>
                                                                    <div className='d-grid'>
                                                                        <label htmlFor="bond_term_condition_link" className='bond-label'>Terms & Conditions Link</label>
                                                                        <input id="bond_term_condition_link" name="bond_term_condition_link" type="text" className='bond-input-upload-link'
                                                                            onChange={e => setFormikInitialData(({ ...formikInitialData, bond_term_condition_link: e.target.value }))} value={formikProps.values?.bond_term_condition_link}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                    <a className='cancel-btn mx-3' onClick={() => navigate(-1)} >Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        {location.state.mode == 'edit' ? 'Update' : 'Save'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                            {/* <div className="row">
                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                    <div className="card-header">
                                                        <h5 className="m-0 header"> Personal Details </h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-sm-3 col-lg-3">
                                                                <form className="app-search card-title p-2 m-0">
                                                                    <div className="position-relative">
                                                                        <label>Issuer Name</label>
                                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-sm-3 col-lg-3">
                                                                <form className="app-search card-title p-2 m-0">
                                                                    <div className="position-relative">
                                                                        <label>Bond Name</label>
                                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-sm-3 col-lg-3">
                                                                <form className="app-search card-title p-2 m-0">
                                                                    <div className="position-relative">
                                                                        <label>Nature of Instrument</label>
                                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6  col-sm-3 col-lg-3">
                                                                <form className="app-search card-title p-2 m-0">
                                                                    <div className="position-relative">
                                                                        <label>Security Type</label>
                                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-sm-3 col-lg-3">
                                                                <form className="app-search card-title p-2 m-0">
                                                                    <div className="position-relative">
                                                                        <label>ST</label>
                                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-sm-3 col-lg-3">
                                                                <form className="app-search card-title p-2 m-0">
                                                                    <div className="position-relative">
                                                                        <label>Security Type Code</label>
                                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-sm-3 col-lg-3">
                                                                <form className="app-search card-title p-2 m-0">
                                                                    <div className="position-relative">
                                                                        <label>Security Code</label>
                                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-sm-3 col-lg-3">
                                                                <form className="app-search card-title p-2 m-0">
                                                                    <div className="position-relative">
                                                                        <label>Guaranted By</label>
                                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6  col-sm-3 col-lg-3">
                                                                <form className="app-search card-title p-2 m-0">
                                                                    <div className="position-relative">
                                                                        <label>ISIN Number</label>
                                                                        <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <br />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <SimpleModal contents={view_content} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" />

        </div>
    );
}

export default BondAdd;

