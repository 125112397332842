import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Depository, Experience, Gender, GenderString, kraStatus, MarriedStatus, PageLinks, PersonVideVerifyStatus, Status, Yes_or_No, AccountStatus } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import { getDisplayData, getFormattedLocalDateTime } from "../common/Utilities";
import AccountStatusModal from "../modals/AccountStatusModal";
import PreviewJSONModal from "../modals/PreviewJSONModal";


const UsersDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const customerId = location.state;
    // const [customerId,setCustomerid] = useState(customerId)
    const [userId, setUserId] = useState()
    const [personalDetails, setPersonlaDetails] = useState<APIData.personalDetail>();
    const [FACTADetails, setFACTADetails] = useState<APIData.FactaDetails[]>([]);
    const [bankDetails, setBankDetails] = useState<APIData.BankDetailsDatas[]>([]);
    const [Cvl_kraAddresDetails, setCvl_kraAddressDetails] = useState<APIData.AddressDatas_cvl_kra>();
    const [digilocker_aAddresDetails, setDigilockerAddressDetails] = useState<APIData.Digilocker_address_data>();
    const [existing_demat_details, setExistingDematDetails] = useState<APIData.ExistingDematAccountDetails[]>([])
    const [new_demat_details, setNewDematDetails] = useState<APIData.NewDematAccountDetails>();
    const [suspended_data, setSuspendedData] = useState({});
    const [account_status_detail, setAccount_status_detail] = useState<APIData.AccountStatusDetails>();
    const [nominee_details, setNomineeDetails] = useState<APIData.NomineeDetails[]>([]);
    const [open, setOpen] = useState(false);
    const [documentDetails, setDocumentDetails] = useState<any>({});
    const [documentProofDetails, setDocumentProofDetails] = useState<APIData.personalDetail>()
    const { setShowPreloader } = useAppStateAPI();
    const [cvlkraJSON, setcvlkraJSON] = useState<any>();
    const [openJSON, setOpenJSON] = useState(false);

    useEffect(() => {
        user_details_datas();
    }, [customerId]);

    const user_details_datas = () => {
        setShowPreloader(true);

        ServerAPI.UserDetail(customerId).then((res) => {
            setDocumentDetails(res.datas['document_details'])
            setAccount_status_detail(res.datas['account_status'])
            setSuspendedData(res.datas['suspension_details']);
            setPersonlaDetails(res.datas['personal_details']);
            setFACTADetails(res.datas['new_demat_account_details']);
            setBankDetails(res.datas['bank_details'])
            setCvl_kraAddressDetails(res.datas['external_details'].cvl_kra);
            setDigilockerAddressDetails(res.datas['external_details'].digilocker);
            setExistingDematDetails(res.datas['exist_demat_account_details']);
            setNewDematDetails(res.datas['new_demat_account_details']);
            setNomineeDetails(res.datas['nominee_details']);
            setDocumentProofDetails(res.datas['document_details'].personal_details)
            setcvlkraJSON(res.datas['external_details'].cvl_kra_records)
        }).finally(() => {
            setShowPreloader(false)
        })
    }

    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
        user_details_datas()
    }

    const status_update = (e: any) => {
        setOpen(true);
        setUserId(e)
    }

    useEffect(() => {
        console.log(existing_demat_details)
    }, [existing_demat_details])


    const handleCVLKRAjson = (data: any) => {
        console.log(data);
        // const jsonString = JSON.stringify(data);
        // const newWindow = window.open("", "JSON Data");
        // if (newWindow) {
        //     newWindow.document.write(`<html><body><pre>${jsonString}</pre></body></html>`);
        // }
        setOpenJSON(true);
    }
    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pt-4">
                                        <div className="page-title-rightds">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item ">Users</li>
                                                <li className="breadcrumb-item active"><b>Detail View</b></li>

                                            </ol>
                                        </div>
                                        <div className='cursor-pointer' onClick={() => navigate(PageLinks.USERS)}>
                                            <p className='mb-0 active'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                                                <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                                            </svg>
                                                <span> Back </span></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header col-xl-12 w-100">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className="my-3 header"> Users Details </h5>
                                                        <div className="">
                                                            <a className='save-btn' onClick={() => { handleCVLKRAjson(cvlkraJSON) }}>CVL KRA json</a>

                                                            <a className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2" onClick={() => navigate(PageLinks.INDIVIDUAL_USER_LOGS, { state: customerId })} > User Logs <i className="fas fa-eye table-icons px-2" ></i></a>
                                                            {account_status_detail?.user_status_key !== AccountStatus.Suspended && <a className="cancel-btn_1 border-light quicksand-medium px-2 py-2" onClick={(e) => status_update(personalDetails?.user_id)}> Account Status: {account_status_detail?.user_status_key === AccountStatus.Active && <span className="text-green quicksand-bold mx-2">Active</span>}
                                                                {account_status_detail?.user_status_key === AccountStatus.Closed && <span className="text-red quicksand-bold mx-2">Closed</span>}
                                                                {account_status_detail?.user_status_key === AccountStatus.Register && <span className="text-blue quicksand-bold mx-2">Register</span>}
                                                                <i className="fa fa-angle-down" aria-hidden="true"></i> </a>}
                                                        </div>
                                                    </div>


                                                    {(account_status_detail?.user_status_key !== AccountStatus.Register && account_status_detail?.user_status_key !== AccountStatus.Suspended) &&
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h5 className="m-0 header"> Account Status</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Status</p>

                                                                        {account_status_detail?.user_status_key === AccountStatus.Active && <h6 className="text-green">Active</h6>}
                                                                        {account_status_detail?.user_status_key === AccountStatus.Closed && <h6 className="text-red"> Closed </h6>}
                                                                    </div>

                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Date</p>
                                                                        {/* <h6>N/A</h6> */}
                                                                        {account_status_detail?.account_status_updated_at === "" || account_status_detail?.account_status_updated_at === null ? <h6> N/A </h6> : <h6>{moment(account_status_detail?.account_status_created_at).format('DD/MM/YYYY')}</h6>}

                                                                    </div>
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Reason</p>
                                                                        {/* <h6>N/A</h6> */}
                                                                        {account_status_detail?.account_status_reason === "" || account_status_detail?.account_status_reason === null ? <h6> N/A </h6> : <h6>{account_status_detail?.account_status_reason}</h6>}

                                                                    </div>
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Remark</p>
                                                                        {/* <h6>N/A</h6> */}
                                                                        {account_status_detail?.account_status_reason === "" || account_status_detail?.account_status_reason === null ? <h6> N/A </h6> : <h6>{account_status_detail?.account_status_reason}</h6>}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }


                                                    {account_status_detail?.user_status_key === AccountStatus.Suspended &&
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h5 className="m-0 header"> Suspension Details</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Start Date</p>
                                                                        {/* <h6>N/A</h6> */}
                                                                        {account_status_detail?.suspended_start_date === "" || account_status_detail?.suspended_start_date === null ? <h6> N/A </h6> : <h6>{moment(account_status_detail?.suspended_start_date).format('DD-MM-YYYY')}</h6>}

                                                                    </div>
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">End Date</p>
                                                                        {account_status_detail?.suspended_end_date === "" || account_status_detail?.suspended_end_date === null ? <h6> N/A </h6> : <h6>{moment(account_status_detail?.suspended_end_date).format('DD-MM-YYYY')}</h6>}

                                                                    </div>
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Reason</p>
                                                                        {account_status_detail?.account_status_reason === "" || account_status_detail?.account_status_reason === null ? <h6> N/A </h6> : <h6>{account_status_detail?.account_status_reason}</h6>}

                                                                    </div>
                                                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                        <p className="detail-title">Remark</p>
                                                                        {account_status_detail?.account_status_remarks === "" || account_status_detail?.account_status_remarks === null ? <h6> N/A </h6> : <h6>{account_status_detail?.account_status_remarks}</h6>}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                        <div className="card-header">
                                                            <h5 className="m-0 header"> Personal Details </h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Customer ID</p>
                                                                    {personalDetails?.customer_id === "" || personalDetails?.customer_id === null ? <h6> N/A </h6> : <h6>{personalDetails?.customer_id}</h6>}

                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">First Name</p>
                                                                    {personalDetails?.fname === null || personalDetails?.fname === "null" ? <h6>N/A</h6> :
                                                                        <h6>{personalDetails?.fname}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Middle Name</p>
                                                                    {personalDetails?.mname === "" || personalDetails?.mname === null ? <h6> N/A </h6> : <h6>{personalDetails?.mname}</h6>}

                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Last Name</p>
                                                                    {personalDetails?.lname === "" || personalDetails?.lname === null ? <h6> N/A </h6> : <h6>{personalDetails?.lname}</h6>}

                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Email Id</p>
                                                                    {personalDetails?.email_id === null || personalDetails?.email_id === null ? <h6> N/A </h6> :
                                                                        <Fragment>
                                                                            <h6> {personalDetails?.email_id}</h6>
                                                                            {personalDetails?.is_email_verified === Status.VERIFY ? <a className="verified mx-2">Verified</a> : <a className="not-verified mx-2">Verify</a>
                                                                            }
                                                                        </Fragment>
                                                                    }
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Mothers Maiden Name</p>
                                                                    {personalDetails?.mothers_maiden_name === "" || personalDetails?.mothers_maiden_name === null ? <h6> N/A </h6> : <h6>{personalDetails?.mothers_maiden_name}</h6>}

                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Date Of Birth</p>
                                                                    {personalDetails?.dob === "" || personalDetails?.dob === null ? <h6> N/A </h6> : <h6>{personalDetails?.dob}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Phone Number - Primary</p>
                                                                    {personalDetails?.mobile_number === null || personalDetails?.mobile_number === null ? <h6> N/A </h6> :
                                                                        <h6>{personalDetails?.mobile_number} {personalDetails?.is_mobile_verified === Status.VERIFY ? <a className="verified mx-2">Verified</a> : <a className="not-verified mx-2">Verify</a>}</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Gender</p>
                                                                    {personalDetails?.gender === null && <h6>N/A</h6>}
                                                                    {personalDetails?.gender === 0 && <h6>N/A</h6>}
                                                                    {(personalDetails?.gender === Gender.MALE || personalDetails?.gender === GenderString.MALE) && <h6>Male</h6>}
                                                                    {(personalDetails?.gender === Gender.FEMALE || personalDetails?.gender === GenderString.FEMALE) && <h6>Female</h6>}
                                                                    {(personalDetails?.gender === Gender.OTHER || personalDetails?.gender === GenderString.OTHER) && <h6>Other</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Marital Status</p>
                                                                    {personalDetails?.married_status === null && <h6> N/A </h6>}
                                                                    {personalDetails?.married_status === MarriedStatus.SINGLE && <h6>Single</h6>}
                                                                    {personalDetails?.married_status === MarriedStatus.MARRIED && <h6>Married</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Profession</p>
                                                                    {personalDetails?.occupation === "" || personalDetails?.occupation === null || personalDetails?.occupation === "null" ? <h6> N/A </h6> : <h6>{personalDetails?.occupation === 1 ? "Salary" : "Business"}</h6>}

                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Income</p>
                                                                    {personalDetails?.annual_income === null && <h6>N/A</h6>}
                                                                    {personalDetails?.annual_income === Annual_income_status.below_one && <h6>Below 1 Lakh</h6>}
                                                                    {personalDetails?.annual_income === Annual_income_status.one_to_five && <h6> 1 - 5 Lakhs</h6>}
                                                                    {personalDetails?.annual_income === Annual_income_status.five_to_ten && <h6>1 - 5 Lakhs</h6>}
                                                                    {personalDetails?.annual_income === Annual_income_status.ten_to_twentyfive && <h6>10 - 25 Lakhs</h6>}
                                                                    {personalDetails?.annual_income === Annual_income_status.above_twntyfive && <h6>Above 25 Lakhs</h6>}

                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Trading Experience</p>
                                                                    {personalDetails?.trading_experience === null && <h6>N/A</h6>}
                                                                    {personalDetails?.trading_experience === Experience.below_one && <h6>Less than 1 year</h6>}
                                                                    {personalDetails?.trading_experience === Experience.one_to_two && <h6>1 - 2 Years </h6>}
                                                                    {personalDetails?.trading_experience === Experience.two_to_five && <h6>2 - 5 Years</h6>}
                                                                    {personalDetails?.trading_experience === Experience.five_to_ten && <h6>5 - 10 Years</h6>}
                                                                    {personalDetails?.trading_experience === Experience.ten_to_twenty && <h6>10 - 20 Years</h6>}
                                                                    {personalDetails?.trading_experience === Experience.twenty_to_twentyfive && <h6>20 - 25 Years</h6>}
                                                                    {personalDetails?.trading_experience === Experience.above_twentyfive && <h6>Above 25 Years</h6>}

                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">IPV</p>
                                                                    {personalDetails?.verification_video != null ?
                                                                        <>
                                                                            {personalDetails?.in_person_verification === PersonVideVerifyStatus.NOT_VERIFY && <h6>{personalDetails.customer_id}.mp4   <Link to={PageLinks.IPVVERIFY} state={customerId} className="not-verified mx-2">Verify</Link> </h6>}
                                                                            {personalDetails?.in_person_verification === PersonVideVerifyStatus.VERIFY && <h6>{personalDetails.customer_id}.mp4   <Link to={PageLinks.IPVVERIFY} state={customerId} className="verified mx-2">Verified</Link> </h6>}
                                                                            {personalDetails?.in_person_verification === PersonVideVerifyStatus.REJECT && <h6>{personalDetails.customer_id}.mp4   <Link to={PageLinks.IPVVERIFY} state={customerId} className="not-verified mx-2">Rejected</Link> </h6>}
                                                                        </> : <h6> N/A </h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Last Update</p>
                                                                    {personalDetails?.personal_details_updated_at === "" || personalDetails?.personal_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(personalDetails?.personal_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header"> PAN Details / GST Details / KRA Status </h5>

                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">PAN</p>
                                                                    {personalDetails?.pan_number != null ?
                                                                        <h6>{personalDetails?.pan_number}
                                                                            {personalDetails?.is_pan_verified === Status.VERIFY && <a className="verified mx-2">Verified</a>}
                                                                            {personalDetails?.is_pan_verified === Status.NOT_VERIFY && <Link to={PageLinks.IPVVERIFY} className="verified mx-2">Verified</Link>}
                                                                        </h6> : <h6>N/A</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">PAN Name (As per Record)</p>
                                                                    {/* <h6>{!personalDetails?.pan_name && "N/A"}</h6> */}
                                                                    {personalDetails?.pan_name === "" || personalDetails?.pan_name === null ? <h6> N/A </h6> : <h6>{personalDetails?.pan_name}</h6>}

                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Father Name (As per Record)</p>
                                                                    {Cvl_kraAddresDetails?.cvl_kra_father_name === "" || Cvl_kraAddresDetails?.cvl_kra_father_name === null ? <h6> N/A </h6> : <h6>{Cvl_kraAddresDetails?.cvl_kra_father_name}</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Aadhaar Seeding Status</p>
                                                                    {Cvl_kraAddresDetails?.cvl_kra_aadhaar_seeding_status === "" || Cvl_kraAddresDetails?.cvl_kra_aadhaar_seeding_status === null ? <h6> N/A </h6> : <h6>{Cvl_kraAddresDetails?.cvl_kra_aadhaar_seeding_status == Yes_or_No.YES ? "Yes" : "No"}</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">IPV Seeding Status</p>
                                                                    {Cvl_kraAddresDetails?.cvl_kra_ipv_seeding_status === "" || Cvl_kraAddresDetails?.cvl_kra_ipv_seeding_status === null ? <h6> N/A </h6> : <h6>{Cvl_kraAddresDetails?.cvl_kra_ipv_seeding_status == Yes_or_No.YES ? "Yes" : "No"}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">GST Available</p>
                                                                    {Cvl_kraAddresDetails?.cvl_kra_gst_available === "" || Cvl_kraAddresDetails?.cvl_kra_gst_available === null ? <h6> N/A </h6> : <h6>{Cvl_kraAddresDetails?.cvl_kra_gst_available}</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">GST No</p>
                                                                    {Cvl_kraAddresDetails?.cvl_kra_gst_number === "" || Cvl_kraAddresDetails?.cvl_kra_gst_number === null ? <h6> N/A </h6> : <h6>{Cvl_kraAddresDetails?.cvl_kra_gst_number}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">API Type</p>
                                                                    {Cvl_kraAddresDetails?.cvl_kra_api_type === "" || Cvl_kraAddresDetails?.cvl_kra_api_type === null ? <h6> N/A </h6> : <h6>{Cvl_kraAddresDetails?.cvl_kra_api_type}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Last Update</p>
                                                                    {Cvl_kraAddresDetails?.cvl_kra_updated_at === "" || Cvl_kraAddresDetails?.cvl_kra_updated_at === null ? <h6> N/A </h6> : <h6>{moment(Cvl_kraAddresDetails?.cvl_kra_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header"> Aadhaar Details </h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Aadhaar Number</p>
                                                                    {personalDetails?.aadhar_number != null ?
                                                                        <h6>{personalDetails?.aadhar_number}
                                                                            {personalDetails?.is_aadhar_verified === Status.VERIFY && <a className="verified mx-2">Verified</a>}
                                                                            {personalDetails?.is_aadhar_verified === Status.NOT_VERIFY && <Link to={PageLinks.IPVVERIFY} className="verified mx-2">Verified</Link>}
                                                                        </h6> : <h6>N/A</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Name (As per Record) </p>
                                                                    {personalDetails?.aadhar_name === "" || personalDetails?.aadhar_name === null ? <h6> N/A </h6> : <h6>{personalDetails?.aadhar_name}</h6>}

                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Date Of Birth</p>
                                                                    {!digilocker_aAddresDetails?.date_of_birth && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.date_of_birth === "" || digilocker_aAddresDetails?.date_of_birth === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.date_of_birth}</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Gender</p>
                                                                    {/* {!digilocker_aAddresDetails?.gender && } */}
                                                                    {digilocker_aAddresDetails?.gender != null || digilocker_aAddresDetails?.gender !== "" ? <>
                                                                        {(digilocker_aAddresDetails?.gender === Gender.MALE || digilocker_aAddresDetails?.gender === GenderString.MALE) && <h6>Male</h6>}
                                                                        {(digilocker_aAddresDetails?.gender === Gender.FEMALE || digilocker_aAddresDetails?.gender === GenderString.FEMALE) && <h6>Female</h6>}
                                                                        {(digilocker_aAddresDetails?.gender === Gender.OTHER || digilocker_aAddresDetails?.gender === GenderString.OTHER) && <h6>Other</h6>}
                                                                    </> : <h6>N/A</h6>}
                                                                    {!digilocker_aAddresDetails?.gender && <h6>N/A</h6>}

                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">API Type </p>
                                                                    {!digilocker_aAddresDetails?.api_type && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.api_type === "" || digilocker_aAddresDetails?.api_type === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.api_type}</h6>}
                                                                </div>
                                                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Type (As per Aadhaar)</p>
                                                                    {!digilocker_aAddresDetails?.address_type && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.address_type === "" || digilocker_aAddresDetails?.address_type === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.address_type}</h6>}
                                                                </div> */}

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 1</p>
                                                                    {!digilocker_aAddresDetails?.care_of && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.care_of && <>
                                                                        {digilocker_aAddresDetails?.care_of === "" || digilocker_aAddresDetails?.care_of === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.care_of},{digilocker_aAddresDetails?.street}</h6>}</>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 2</p>
                                                                    {!digilocker_aAddresDetails?.landmark && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.landmark && <>
                                                                        {digilocker_aAddresDetails?.landmark === "" || digilocker_aAddresDetails?.landmark === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.landmark},{digilocker_aAddresDetails?.village_town_city}</h6>}</>}

                                                                </div>
                                                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 3</p>
                                                                    {!digilocker_aAddresDetails?.address_line_3 && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.address_line_3 === "" || digilocker_aAddresDetails?.address_line_3 === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.address_line_3}</h6>}
                                                                </div> */}
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">City</p>
                                                                    {!digilocker_aAddresDetails?.village_town_city && <h6>N/A</h6>}

                                                                    {digilocker_aAddresDetails?.village_town_city === "" || digilocker_aAddresDetails?.village_town_city === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.district}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">State</p>
                                                                    {!digilocker_aAddresDetails?.state && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.state === "" || digilocker_aAddresDetails?.state === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.state}</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Pincode</p>
                                                                    {!digilocker_aAddresDetails?.pincode && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.pincode === "" || digilocker_aAddresDetails?.pincode === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.pincode}</h6>}
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Last Update</p>
                                                                    {digilocker_aAddresDetails?.digilocker_details_updated_at === "" || digilocker_aAddresDetails?.digilocker_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(digilocker_aAddresDetails?.digilocker_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header"> Updated Address</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">

                                                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Type </p>
                                                                    {!personalDetails?.address_type && <h6>N/A</h6>}
                                                                    {personalDetails?.address_type === "" || personalDetails?.address_type === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_type}</h6>}
                                                                </div> */}

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 1</p>
                                                                    {personalDetails?.address_line_1 === "" || personalDetails?.address_line_1 === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_line_1.split("null")}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 2</p>
                                                                    {personalDetails?.address_line_2 === "" || personalDetails?.address_line_2 === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_line_2.split("null")}</h6>}
                                                                </div>
                                                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 3</p>
                                                                    {personalDetails?.address_line_3 === "" || personalDetails?.address_line_3 === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_line_3.split("null")}</h6>}
                                                                </div> */}
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">State</p>
                                                                    {personalDetails?.address_state === "" || personalDetails?.address_state === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_state}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">City</p>
                                                                    {personalDetails?.address_city === "" || personalDetails?.address_city === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_city}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Pincode</p>
                                                                    {personalDetails?.address_zip === "" || personalDetails?.address_zip === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_zip}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Last Update</p>
                                                                    {personalDetails?.personal_details_updated_at === "" || personalDetails?.personal_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(personalDetails?.personal_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3"> </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header"> Nominee Details</h5>
                                                        </div>
                                                        {
                                                            nominee_details != undefined && nominee_details.length > 0 ? nominee_details.map((nominee: any, inx: number) => {
                                                                return (
                                                                    <div className="card-body">
                                                                        <h5 className="m-0 header py-4"> Nominee {inx + 1}</h5>
                                                                        <div className="row">
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Title</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_title)}</h6>

                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">First Name</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_fname)}</h6>
                                                                            </div>
                                                                            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Middle Name</p>
                                                                    <h6>{getDisplayData(nominee_details?.nominee_details_mname)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Last Name</p>
                                                                     <h6>{getDisplayData(nominee_details?.nominee_details_lname)}</h6>
                                                                </div> */}
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Relationship with Application</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_relationship)}</h6>
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Date Of Birth</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_dob)}</h6>
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Phone Number - Primary</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_mobile_number)}</h6>
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">
                                                                                    {nominee?.nominee_details_identification == 1 &&
                                                                                        <span className="my-account-input-title">PAN Number</span>
                                                                                    }
                                                                                    {nominee?.nominee_details_identification == 2 &&
                                                                                        <span className="my-account-input-title">Aadhar Card Number</span>
                                                                                    }
                                                                                    {nominee?.nominee_details_identification == 3 &&
                                                                                        <span className="my-account-input-title">Voter ID Number</span>
                                                                                    }
                                                                                    {nominee?.nominee_details_identification == 4 &&
                                                                                        <span className="my-account-input-title">Driving License Number</span>
                                                                                    }
                                                                                    {nominee?.nominee_details_identification == 5 &&
                                                                                        <span className="my-account-input-title">Passport</span>
                                                                                    }
                                                                                </p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_identification_number)}</h6>
                                                                            </div>

                                                                            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Type (As per KRA/Aadhaar)</p>
                                                                    <h6>{!nominee_details?.nominee_details_address_type && "N/A"}</h6>
                                                                    {nominee_details?.nominee_details_address_type === "" || nominee_details?.nominee_details_address_type === null ? <h6> N/A </h6> : <h6>{nominee_details?.nominee_details_address_type}</h6>}
                                                                </div> */}
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Address Line 1</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_address_line_1)}</h6>
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Address Line 2</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_address_line_2)}</h6>

                                                                            </div>
                                                                            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 3</p>
                                                                    <h6>{!nominee_details?.nominee_details_address_line_3 && "N/A"}</h6>
                                                                    {nominee_details?.nominee_details_address_line_3 === "" || nominee_details?.nominee_details_address_line_3 === null ? <h6> N/A </h6> : <h6>{nominee_details?.nominee_details_address_line_3}</h6>}
                                                                </div> */}
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">State</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_address_state)}</h6>

                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">City</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_address_city)}</h6>
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Pincode</p>
                                                                                <h6>{getDisplayData(nominee?.nominee_details_address_zip)}</h6>
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Created at</p>
                                                                                <h6>{getFormattedLocalDateTime(nominee?.nominee_details_created_at)}</h6>
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Last Update</p>
                                                                                <h6>{getFormattedLocalDateTime(nominee?.nominee_details_updated_at)}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                                : "No Data Found"
                                                        }
                                                    </div>

                                                    <br />
                                                    {FACTADetails.length > 0 &&
                                                        <>

                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header"> FACTA Details</h5>
                                                                </div>
                                                                {FACTADetails.map((items: APIData.FactaDetails, i: any) => {
                                                                    return (
                                                                        <div className="card-body" key={i}>
                                                                            <div className="row">
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Where were you born?</p>
                                                                                    {items.born_place === "" || items.born_place === null ? <h6> N/A </h6> : <h6>{items.born_place}</h6>}
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Are you a citizen of the U.S.A?</p>
                                                                                    {items.dp_resident_usa === Yes_or_No.YES ? <h6>Yes</h6> : <h6>No</h6>}
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Is your country of tax Residency, India?</p>
                                                                                    {items.dp_resident_india === Yes_or_No.YES ? <h6>Yes</h6> : <h6>No</h6>}
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Your primary source of wealth?</p>
                                                                                    {items.primary_source === "" || items.primary_source === null ? <h6>N/A</h6> : <h6>{items.primary_source}</h6>}
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Last Update</p>
                                                                                    {items?.demat_account_details_updated_at === "" || items?.demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(items?.demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>

                                                        </>
                                                    }

                                                    <br />
                                                    {bankDetails.length > 0 &&
                                                        <>
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header"> BANK Details</h5>
                                                                </div>
                                                                {bankDetails.map((banks_details: APIData.BankDetailsDatas, i: any) => {
                                                                    return (
                                                                        <div className="card-body" key={i}>
                                                                            <div className="row">
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Status</p>
                                                                                    {banks_details.is_primary === Yes_or_No.YES ? <h6 className="text-green">Active</h6> : <h6 className="text-red">In Active</h6>}
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Name (as per Bank Records)</p>
                                                                                    {!banks_details.account_name && <h6>N/A</h6>}
                                                                                    {banks_details.account_name === "" || banks_details.account_name === null ? <h6>N/A</h6> : <h6>{banks_details.account_name}</h6>}
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Bank Name</p>
                                                                                    {banks_details.bank_name === "" || banks_details.bank_name === null ? <h6>N/A</h6> : <h6>{banks_details.bank_name}</h6>}
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Branch Name</p>
                                                                                    {banks_details.branch_name === "" || banks_details.branch_name === null ? <h6>N/A</h6> : <h6>{banks_details.branch_name}</h6>}

                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Account Number</p>
                                                                                    {banks_details.account_number === "" || banks_details.account_number === null ? <h6>N/A</h6> : <h6>{banks_details.account_number}</h6>}
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">IFSC Code</p>
                                                                                    {banks_details.ifsc_code === "" || banks_details.ifsc_code === null ? <h6>N/A</h6> : <h6>{banks_details.ifsc_code}</h6>}
                                                                                </div>

                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Branch Address</p>
                                                                                    {banks_details.branch_address === "" || banks_details.branch_address === null ? <h6>N/A</h6> : <h6>{banks_details.branch_address}</h6>}
                                                                                </div>

                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Account Type 1</p>
                                                                                    {banks_details.account_type_1 === "" || banks_details.account_type_1 === null ? <h6>N/A</h6> : <h6>{banks_details.account_type_1 === 1 ? "Saving" : "Current"}</h6>}

                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Account Type 2</p>
                                                                                    {banks_details.account_type_2 === "" || banks_details.account_type_2 === null ? <h6>N/A</h6> : <h6>{banks_details.account_type_2 === 1 ? "Single" : "Current"}</h6>}
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                    <p className="detail-title">Last Update</p>
                                                                                    {banks_details?.bank_details_updated_at === "" || banks_details?.bank_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(banks_details?.bank_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                                                                </div>
                                                                                <div className="col-sm-9 col-md-9 col-lg-9"></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}

                                                            </div>

                                                        </>}

                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header"> Existing Demat Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                {/* {existing_demat_details.length > 0 && <> */}
                                                                {existing_demat_details.map((res: APIData.ExistingDematAccountDetails, i: any) => {
                                                                    return (
                                                                        <>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3" key={i}>
                                                                                <p className="detail-title">Status</p>
                                                                                {/* <h6>{!res.status && "N/A"}</h6>
                                                                                {res?.status === null ? <h6> N/A </h6> : <h6>{res?.status}</h6>} */}
                                                                                {res?.exist_demat_account_details_status_key === Yes_or_No.YES ? <h6 className="text-green">Active</h6> : <h6 className="text-red">In Active</h6>}
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Depository</p>
                                                                                {/* {res?.depository === "" || res?.depository === null ? <h6> N/A </h6> : <h6>{Number(res?.depository) === Depository.CSDL ? "CSDL" : "NSDL"}</h6>} */}
                                                                                {getDisplayData(res.depository)}
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">DP Name</p>
                                                                                {res?.dp_name === "" || res?.dp_name === null ? <h6> N/A </h6> : <h6>{res?.dp_name}</h6>}
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">DP ID</p>
                                                                                {res?.dp_id === "" || res?.dp_id === null ? <h6> N/A </h6> : <h6>{res?.dp_id}</h6>}
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Client ID</p>
                                                                                <h6>{!res.client_id && "N/A"}</h6>
                                                                                {res?.client_id === null ? <h6> N/A </h6> : <h6>{res?.client_id}</h6>}
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                <p className="detail-title">Last Update</p>
                                                                                {res?.exist_demat_account_details_updated_at === "" || res?.exist_demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(res?.exist_demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                                                            </div>
                                                                            <div className="col-sm-12 col-md-12 col-lg-12"></div>

                                                                        </>
                                                                    )
                                                                })}
                                                                {/* </>} */}
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header"> New Demat Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                {/* {new_demat_details.length > 0 && <> */}
                                                                {/* {new_demat_details.map((res: APIData.NewDematAccountDetails, i: any) => {
                                                                        return (
                                                                            <> */}
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Status</p>
                                                                    {/* <h6>{getDisplayData(new_demat_details?.demat_account_details_status)}</h6> */}
                                                                    {new_demat_details?.demat_account_details_status === Yes_or_No.YES ? <h6 className="text-green">Active</h6> : <h6 className="text-red">In Active</h6>}
                                                                    {/* {res?.born_place === null ? <h6> N/A </h6> : <h6>{res?.born_place}</h6>} */}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Depository</p>
                                                                    <h6>{getDisplayData(new_demat_details?.depository)}</h6>
                                                                    {/* {res?.dp_resident_usa === "" || res?.depository === null ? <h6> N/A </h6> : <h6>{Number(res?.dp_resident_usa) === Yes_or_No.YES? "Yes":"No" }</h6>} */}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">DP Name</p>
                                                                    <h6>{getDisplayData(new_demat_details?.fname) + ' ' + getDisplayData(new_demat_details?.lname)}</h6>
                                                                    {/* {res?.dp_resident_india === "" || res?.depository === null ? <h6> N/A </h6> : <h6>{Number(res?.dp_resident_india) === Yes_or_No.YES? "Yes":"No" }</h6>} */}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">DP ID</p>
                                                                    <h6>{getDisplayData(new_demat_details?.dp_account_number)}</h6>
                                                                    {/* {res?.primary_source === "" || res?.primary_source === null ? <h6> N/A </h6> : <h6>{res?.primary_source}</h6>} */}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Client ID</p>
                                                                    <h6>{getDisplayData(new_demat_details?.dp_account_number)}</h6>
                                                                    {/* {res?.client_id === null ? <h6> N/A </h6> : <h6>{res?.client_id}</h6>} */}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Created at</p>
                                                                    <h6>{getFormattedLocalDateTime(new_demat_details?.demat_account_details_created_at)}</h6>
                                                                    {/* {items?.demat_account_details_updated_at === "" || items?.demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(items?.demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>} */}
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Last Update</p>
                                                                    <h6>{getFormattedLocalDateTime(new_demat_details?.demat_account_details_updated_at)}</h6>
                                                                    {/* {items?.demat_account_details_updated_at === "" || items?.demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(items?.demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>} */}
                                                                </div>
                                                                <div className="col-sm-9 col-md-9 col-lg-9"></div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Account Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Registration Date</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Empanelment Date</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Empanelment Frequency</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Empanelment With</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Net worth</p>
                                                                    <h6>N/A</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">AUM</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Custodian Segment</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Custodian Settlement</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Settlement Mode</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Customer for IPO Only</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">IPO Category</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">IPO SUB Category</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">NSCCL & ICCL UCC</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Client Type</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ITR Submitted</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Remarks</p>
                                                                    <h6>N/A</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Last Update</p>
                                                                    <h6>N/A</h6>
                                                                    {/* {items?.demat_account_details_updated_at === "" || items?.demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(items?.demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>} */}
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Document Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="table-rep-plugin">
                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                        <thead className="city-table">
                                                                            <tr>
                                                                                <th className="text-center">Sr.No</th>
                                                                                <th className="text-center">Upload Date</th>
                                                                                <th className="text-center">Document Name</th>
                                                                                <th className="text-center">Uploads</th>
                                                                                <th className="text-center">Verified</th>
                                                                                <th className="text-center">Remarks</th>
                                                                                <th className="text-center">Download</th>
                                                                            </tr>
                                                                        </thead>


                                                                        <tbody>
                                                                            {documentDetails.esigned_pdf &&
                                                                                <tr>
                                                                                    {(documentDetails.esigned_pdf !== "" || documentDetails.esigned_pdf !== null) && <>
                                                                                        <td className="text-center">01 </td>
                                                                                        <td className="text-center">N/A</td>
                                                                                        <td className="text-center">e-Sign</td>
                                                                                        <td className="text-center text-decoration-underline">{documentDetails.esigned_pdf_name}</td>
                                                                                        <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                        <td className="text-center">N/A</td>
                                                                                        <td className="text-center"><i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(documentDetails.esigned_pdf)}></i>   </td>
                                                                                    </>}
                                                                                </tr>}

                                                                            {documentProofDetails && <tr>
                                                                                {(documentProofDetails.proof_front_image !== "" || documentProofDetails.proof_front_image !== null) &&
                                                                                    <>
                                                                                        <td className="text-center">02 </td>
                                                                                        <td className="text-center">{(documentProofDetails.personal_details_updated_at !== "" || documentProofDetails.personal_details_updated_at !== null) ? <> {moment(documentProofDetails.personal_details_updated_at).format("DD-MM-YYYY")}</> : <> N/A </>}</td>
                                                                                        <td className="text-center">{(documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null) ? <> {documentProofDetails.proof_type} Front</> : <> N/A </>}</td>
                                                                                        <td className="text-center text-decoration-underline">{(documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null) ? <> {documentProofDetails.proof_type} Front.Pdf</> : <> N/A </>}</td>
                                                                                        <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                        <td className="text-center">N/A</td>
                                                                                        <td className="text-center"><i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(documentProofDetails.proof_front_image)}></i>   </td>
                                                                                    </>}

                                                                            </tr>}

                                                                            {documentProofDetails && <tr>
                                                                                {(documentProofDetails.proof_back_image !== "" || documentProofDetails.proof_back_image !== null) &&
                                                                                    <>
                                                                                        <td className="text-center">03 </td>
                                                                                        <td className="text-center">{(documentProofDetails.personal_details_updated_at !== "" || documentProofDetails.personal_details_updated_at !== null) ? <> {moment(documentProofDetails.personal_details_updated_at).format("DD-MM-YYYY")}</> : <> N/A </>}</td>
                                                                                        <td className="text-center">{(documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null) ? <> {documentProofDetails.proof_type} Back</> : <> N/A </>}</td>
                                                                                        <td className="text-center text-decoration-underline">{(documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null) ? <> {documentProofDetails.proof_type} Back.Pdf</> : <> N/A </>}</td>
                                                                                        <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                        <td className="text-center">N/A</td>
                                                                                        <td className="text-center"><i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(documentProofDetails.proof_back_image)}></i>   </td>
                                                                                    </>}

                                                                            </tr>}

                                                                            {documentDetails.demat_signature &&
                                                                                <tr>
                                                                                    {(documentDetails['demat_signature'].demat_signature !== "" || documentDetails['demat_signature'].demat_signature !== null) && <>
                                                                                        <td className="text-center">04 </td>
                                                                                        <td className="text-center">{(documentDetails['demat_signature'].demat_account_details_updated_at !== "" || documentDetails['demat_signature'].demat_account_details_updated_at !== null) ? <> {moment(documentDetails['demat_signature'].demat_account_details_updated_at).format("DD-MM-YYYY")}</> : <> N/A </>}</td>
                                                                                        <td className="text-center">Demat Signature</td>
                                                                                        <td className="text-center text-decoration-underline">Demat Signature.pdf </td>
                                                                                        <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                        <td className="text-center">N/A</td>
                                                                                        <td className="text-center"><i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(documentDetails['demat_signature'].demat_signature)}></i>   </td>
                                                                                    </>}
                                                                                </tr>}
                                                                            {/* <tr>
                                                                                <td className="text-center">03 </td>
                                                                                <td className="text-center">30-08-2022</td>
                                                                                <td className="text-center">Aadhaar Card</td>
                                                                                <td className="text-center">AadhaarCard.jpg</td>
                                                                                <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                <td className="text-center"></td>
                                                                                <td className="text-center"><i className="fa fa-download" aria-hidden="true"></i>   </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">04 </td>
                                                                                <td className="text-center">30-08-2022</td>
                                                                                <td className="text-center">Cancelled Cheque</td>
                                                                                <td className="text-center">CancelledCheque.jpg</td>
                                                                                <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                <td className="text-center"></td>
                                                                                <td className="text-center"><i className="fa fa-download" aria-hidden="true"></i>   </td>
                                                                            </tr> */}
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header"> Dealer Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Dealer Type</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Dealer Name</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">From Date</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">From Date</p>
                                                                    <h6>N/A</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                {/*  */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <AccountStatusModal userId={userId} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            {/* <SimpleModal contents={res.suspended_remarks} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}
            <PreviewJSONModal contents={cvlkraJSON} closeModel={() => setOpenJSON(false)} showModel={openJSON} />

        </div >
    );
}

export default UsersDetails;