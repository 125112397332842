export enum PageLinks {
    LOGIN = "/login",
    DASHBOARD = "/dashboard",
    CITIES = "/cities",
    USERS = "/users",
    USERSDETAILS = "/users-details",
    IPVVERIFY = "/ipv-verification",
    PANVERIFY = "/pan-verification",
    SUSPENDED_USER_LIST = "/suspended-user-list",
    ALL_USERS_LOGS = "/all-users-logs",
    INDIVIDUAL_USER_LOGS = "/user-logs",
    BOND_LIST = "/bond-list",
    BOND_ADD = "/bond-add",
    IPO_ADD = "/ipo-add",
    BOND_DETAIL = "/bond-detail",
    IPO_DETAIL = "/ipo-detail",
    IPO_FORM_LIST = '/ipo-form-list',
    IPO_FORM_ADD = '/ipo-form-add',
    DEALER_LIST = '/dealers-list',
    DEALER_ADD = '/dealers-add',
    DEALER_Detail = '/dealer-detail',
    DISTRIBUTOR_LIST = '/distributor-list',
    DISTRIBUTOR_ADD = '/distributor-add',
    DISTRIBUTOR_TYPE_LIST = '/distributor-type-list',
    DISTRIBUTOR_TYPE_ADD = '/distributor-type-add',
    DISTRIBUTOR_SUB_TYPE_LIST = '/distributor-sub-type-list',
    DISTRIBUTOR_SUB_TYPE_ADD = '/distributor-sub-type-add',
    DISTRIBUTOR_CATEGORY_LIST = '/distributor-category-list',
    DISTRIBUTOR_CATEGORY_ADD = '/distributor-category-add',
    BRAND_TAG_LIST = '/brand-tag-list',
    BRAND_TAG_ADD = '/brand-tag-add',
    ELIGIBLE_INVENTOR_LIST = '/eligible-investor-list',
    ELIGIBLE_INVENTOR_ADD = '/eligible-investor-add',
    BROKER_LIST = '/broker-list',
    BROKER_ADD = '/broker-add',
    ASBA_LIST = '/ASBA-list',
    ASBA_ADD = '/ASBA-add',
    SGB_LIST = '/SGB-list',
    SGB_ADD = '/SGB-add',
    SGB_DETAIL = '/SGB-detail',
    IPO_FORM_ALLOCATION = '/ipo-form-allocation',
    BOND_BROKERAGE_CATEGORIE_LIST = '/bond-brokerage-categorie-list',
    BOND_BROKERAGE_CATEGORIE_ADD = '/bond-brokerage-categorie-add',
    CUSTOMER_BOOK = '/customer-book',
    CUSTOMER_BOOK_DETAIL_VIEW = '/customer-book-detail-view',
    BOND_SUBCRIBE_LIST = '/bond-subcribe-list'
}

export enum Endpoints {
    login = 'send-otp',
    verify_otp = 'otp-verify',
    usersList = 'all_users_data_details',
    user_details = 'specific_user_details',
    verify_ipv = 'admin_ipv_details',
    update_ipv_status = "admin_ipv_details",
    suspended_list = "suspension_users_data_details",
    update_account_status = "suspension_status_update",
    suspension_status_update = "suspension_status_update",
    suspension_status_active = "suspension_status_update",
    all_users_logs = "user_logs_details/list",
    individual_user_logs = "user_logs_details",
    user_logs_module_dropdown = "user_logs_module",
    dealers = 'dealers',
    setDealerStatus = 'dealers/set-status',
    dealerLoginDetails = 'dealers/login-details',
    bond = 'bonds',
    bondDetail = 'specific_bond_details',
    brandTags = 'brand-tags',
    eligibleInvestor = 'eligible-investors',
    IPO = 'ipo',
    DocumentUpload = 'ipo/documents-upload',
    populateBond = 'populate_bonds',
    populateSGB = 'populate_sgb',
    getSpecificSGB = 'specific_sgb_details',
    ipoFormAllocation = 'ipo-form-allocations',
    Distributor_type = 'distributor-types',
    Distributor_sub_type = 'distributor-sub-types',
    DistributorCategory = 'distributor-categories',
    BrokerageCategory = 'bond-brokerage-categories',
    BondRatingList = 'bond_rating_list',
    CustomerOrderList = 'all_orders',
    SpecificOrderList = 'specific_orders_id',
    BondSubcribe = 'bond-subscribes'
}

export const Strings = {
    ValidationRequired: 'Required',
    ValidationSelect: 'Please Select',
    ValidationEmail: 'Not a Valid Email',
    ValidationNumber: 'Not a Valid Numeric',
    ValidationMax: (max: number) => 'Max ' + max + ' Characters',
    ErrorAPIFailed: 'Error Occurred while processing the request',
    // ValidationInPercentage: 'Required Feild'
}

export const wysiwygToolbar = {
    options: ['inline', 'list', 'link', 'emoji', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    }
}

export const NCDDetailsList = [
    {
        header: [
            // {
            //     name: 'Sr.No'
            // },
            // {
            //     name: 'Name'
            // },
            {
                name: 'Serial 1'
            }
        ],
        content: [
            {
                name: 'Frequency of Interest Payment',
                id: 1
            },
            {
                name: 'Minimum Application (₹)',
                id: 1
            },
            {
                name: 'In Multiples of thereafter (₹)',
                id: 1
            },
            {
                name: 'Face Value/ Issue Price of NCDs (`/ NCD) (₹)',
                id: 1
            },
            {
                name: 'Tenor',
                id: 1
            },
            {
                name: 'Coupon (% per annum)',
                id: 1
            },
            {
                name: 'Effective Yield (% per annum)',
                id: 1
            },
            {
                name: 'Mode of Interest Payment',
                id: 1
            },
            {
                name: 'Redemption Amount (₹ / NCD) on Maturity',
                id: 1
            },
            {
                name: 'Put and Call Option',
                id: 1
            },
            {
                name: 'Type Here',
                id: 1
            },
            {
                name: 'Type Here',
                id: 1
            },
            {
                name: 'Type Here',
                id: 1
            }
        ]
    }
]
export const ShortTermList = [
    {
        header: [
            {
                name: 'Sr.No'
            },
            {
                name: 'Name'
            },
            {
                name: 'Series 1 (36 Months)'
            },
            {
                name: 'Series 2 (36 Months)'
            },
            {
                name: 'Series 3 (36 Months)'
            },
            {
                name: 'Series 4 (36 Months)'
            },
            {
                name: 'Series 5 (36 Months)'
            },
            {
                name: 'Series 6 (36 Months)'
            },
            {
                name: 'Action'
            }
        ],
        content: [
            {
                name: 'Cat I - Institutional Investor',
                // series_Value: 'Monthly'
            },
            {
                name: 'Cat II - Institutional Investor',
                // series_Value: 'Monthly'
            },
            {
                name: 'Cat III - Institutional Investor',
                // series_Value: 'Monthly'
            },
            {
                name: 'Cat IV - Institutional Investor',
                // series_Value: 'Monthly'
            }
        ]
    }
]

export const APIResult = {
    SUCCESS: true,
    FAILURE: false
}

export const Status = {
    VERIFY: 1,
    NOT_VERIFY: 0
}

export const Gender = {
    MALE: 1,
    FEMALE: 2,
    OTHER: 3
}
export const GenderString = {
    MALE: "M",
    FEMALE: "F",
    OTHER: "O"
}
export const MarriedStatus = {
    SINGLE: 1,
    MARRIED: 2
}
export const Annual_income_status = {
    below_one: 1,
    one_to_five: 2,
    five_to_ten: 3,
    ten_to_twentyfive: 4,
    above_twntyfive: 5

}

export const AccountStatus = {
    Register: 1,
    Active: 2,
    Suspended: 3,
    Closed: 4
}

export const Experience = {
    below_one: 1,
    one_to_two: 2,
    two_to_five: 3,
    five_to_ten: 4,
    ten_to_twenty: 5,
    twenty_to_twentyfive: 6,
    above_twentyfive: 7
}

export const PersonVideVerifyStatus = {
    VERIFY: 1,
    NOT_VERIFY: 0,
    REJECT: 2
}

export const kraStatus = {
    YES: 1,
    NO: 0
}

export const BondType = {
    IPO: 1,
    ADminBond: 2,
    ExternalAPIBond: 3,
    SGB: 4
}
export const Yes_or_No = {
    YES: 1,
    NO: 0
}

export const Depository = {
    CSDL: 1,
    NSDL: 2
}

export const API = {
    // BaseUrl: "https://trust-api.trustmony.com/admin/api/v1/", // Dev url
    BaseUrl: "https://api.trustmony.com/admin/api/v1/", //Live url
    EndPoint: Endpoints,
    Result: APIResult
}

export const DealerStatus = {
    Active: 1,
    Disabled: 0
}

export const SGBUpcomingStatus = {
    Active: 1,
    Disabled: 0
}

export const NCDStatus = {
    Active: 1,
    Disabled: 0
}

export const DistributorTypeStatus = {
    Active: 1,
    Disabled: 0
}
export const DistributorSubTypeStatus = {
    Active: 1,
    Disabled: 0
}
export const DistributorCategoryStatus = {
    Active: 1,
    Disabled: 0
}
export const BrandTagStatus = {
    Active: 1,
    Disabled: 0
}
export const EligibleTagStatus = {
    Active: 1,
    Disabled: 0
}
export const BondIPOStatus = {
    Active: 1,
    Disabled: 0
}

export const CustomerOrderStatus = {
    Success: 20,
    Pending: 30,
    UnderProcessing: 40
}