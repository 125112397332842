import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { PageLinks } from '../common/Constants';
import { APIData } from '../common/DataTypes';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import { getIsLogin } from '../common/Utilities';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';

var startSerialNo = 0;
const AllUsersLogs: React.FC = () => {

    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [userLogList, setUserlogList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [search_text, setSearchText] = useState('');
    const [select_module, setSelectModule] = useState<any>("All")
    const [module_list, setModuleList] = useState<any[]>([])
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const currPage = currentPage ? currentPage : 1;
    startSerialNo = (currPage * 10) - 10;

    useEffect(() => {
        getIsLogin().then(status => {
            console.log(status)
            if (!status) {
                navigate(PageLinks.LOGIN);
            }
        })
    }, [])

    useEffect(() => {
        setShowPreloader(true);

        ServerAPI.AllUserLogs(pageNumber, search_text, toData, fromDate, select_module).then((res) => {
            setUserlogList(res.user_logs);
            setTotalCount(res.total_count);

        }).finally(() => {
            setShowPreloader(false);
        })

    }, [pageNumber, search_text, toData, fromDate, select_module])


    useEffect(() => {
        ServerAPI.UserLogsModule().then((res: any) => {
            setModuleList(res)
        })

    }, [pageNumber])

    // const handleClickOpen = () => { };

    // const handleClose = (e: any) => {
    //     setOpen(e);

    // }

    // const handleClose_modal = (e: any) => {
    //     setOpenModal(e);
    // }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });


    return (
        // <div>
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item active ">User Logs</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <div className="row" style={{ paddingBottom: "10px" }}>
                                                <div className="col-xl-5">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h4 className="card-heading p-2 m-0">User Logs</h4>
                                                        <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                                        <form className="app-search card-title p-2 m-0">
                                                            <div className="position-relative">
                                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="col-xl-7 text-end">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative ">
                                                                <select className='select-style' name='user_status' onChange={(e) => setSelectModule(e.target.value)} >
                                                                    {module_list != undefined && module_list.map((res: any, i: any) =>
                                                                        <option key={i} id={res.user_logs_module_id} value={res.user_logs_module_name} >{res.user_logs_module_name}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </form>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={true} name="dob" format="dd/MM/yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" onChange={setFromDate} value={fromDate}
                                                                    calendarIcon={
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 69.398 79.312">
                                                                            <path id="Path_23208" data-name="Path 23208" d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z" transform="translate(-308.128)" />
                                                                        </svg>
                                                                    } /> */}
                                                                <DatePicker
                                                                    selected={fromDate}
                                                                    onChange={(date: Date) => setFromDate(date)}
                                                                    placeholderText="From Date"
                                                                    className='date-picker-style'
                                                                    customInput={(<CustomInput />)}
                                                                />

                                                            </div>
                                                        </form>
                                                        <h4 className="card-title p-2 m-0">TO</h4>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={false} name="dob" format="dd/MM/yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" onChange={setToData} value={toData}
                                                                    calendarIcon={
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 69.398 79.312">
                                                                            <path id="Path_23208" data-name="Path 23208" d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z" transform="translate(-308.128)" />
                                                                        </svg>
                                                                    } /> */}
                                                                <DatePicker
                                                                    selected={toData}
                                                                    onChange={(date: Date) => setToData(date)}
                                                                    placeholderText="To Date"
                                                                    className='date-picker-style'
                                                                    customInput={(<CustomInput />)}
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                            {/* <div className="card-body"> */}
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive " data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Cust. ID</th>
                                                                <th>Customer Name</th>
                                                                <th>Mobile Number</th>
                                                                <th>Updated Date & Time</th>
                                                                <th>IP Address</th>
                                                                <th className='w-10'>Platform</th>
                                                                <th>Module</th>
                                                                <th>Method</th>
                                                                <th>Status</th>
                                                                <th>Last Login Date&Time</th>
                                                            </tr>
                                                        </thead>
                                                        {userLogList.length > 0 ? <>
                                                            {userLogList.map((res: APIData.AlluserLogs, i: any) => (
                                                                <tbody key={i}>
                                                                    <tr >
                                                                        <td> {startSerialNo+i + 1} </td>
                                                                        <td>{res.customer_id !== "" || res.customer_id !== null ? <>{res.customer_id}</> : "N/A"}</td>
                                                                        <td>{res.user_name !== "" || res.user_name !== null ? <>{res.user_name}</> : "N/A"}</td>
                                                                        <td>{res.user_mobile_number !== "" || res.user_mobile_number !== null ? <>{res.user_mobile_number}</> : "N/A"}</td>
                                                                        <td>{res.user_logs_created_at != null && moment(res.user_logs_created_at).format("DD-MM-YYYY,  hh:mm A")}</td>
                                                                        <td>{res.ip_address !== "" || res.ip_address !== null ? <>{res.ip_address}</> : "N/A"}</td>
                                                                        {/* {!res.platform ? <td>N/A</td>: */}
                                                                        <td>{res.platform !== "" || res.platform !== null ? <>{res.platform}</> : "N/A"}</td>
                                                                        <td>{res.module !== "" || res.module !== null ? <>{res.module}</> : "N/A"}</td>

                                                                        {res.operation_type === "" || res.operation_type === null && <td>N/A</td>}
                                                                        {res.operation_type === "1" && <td>POST</td>}
                                                                        {res.operation_type === "2" && <td>UPDATE</td>}
                                                                        {res.operation_type === "3" && <td>DELETE</td>}
                                                                        {res.operation_type === "4" && <td>GET</td>}


                                                                        {res.operation_status === "" || res.operation_status === null && <td>N/A</td>}
                                                                        {res.operation_status === "1" && <td>Success</td>}
                                                                        {res.operation_status === "2" && <td>Failed</td>}
                                                                        {res.user_last_login && <td>{moment(res.user_last_login).format("DD-MM-YYYY,  hh:mm A")}</td>}
                                                                        {/* {res.operation_status === "0" && <td>Success</td>} */}

                                                                    </tr>


                                                                </tbody>

                                                            ))}
                                                        </> : <tbody>
                                                            <tr className='py-4'>
                                                                <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                            </tr>
                                                        </tbody>}


                                                    </table>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-5">
                                                    {totalCount > 10 ?
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {userLogList.length} of {totalCount} entries</div> :
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                                        <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                                            setPageNumber(Number(value));
                                                            setCurrentPage(Number(value));
                                                        }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default AllUsersLogs;

