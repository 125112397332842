import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { Depository, PageLinks } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';


const Users: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [userList, setUserList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();

    // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    useEffect(() => {
        suspended_list();
    }, [pageNumber, search_text, toData, fromDate, preData, open])


    const suspended_list = () => {
        setShowPreloader(true);
        ServerAPI.SuspentedUserList(pageNumber, search_text, toData, fromDate).then((res) => {
            setUserList(res.datas);
            setTotalCount(res.total_count);

        }).finally(() => {
            setShowPreloader(false);
        })

    }

    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
        // suspended_list();
    }

    const handleClose_modal = (e: any) => {
        setOpenModal(e);
        suspended_list();
    }

    const activate_user = (id: any) => {
        setOpenModal(true);
        setUserId(id);
    }

    const view_more_content = (e: any) => {
        setViewContent(e)
        setOpen(true)
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    return (
        // <div>
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Users </a></li>
                                                <li className="breadcrumb-item active ">Suspend Users</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            {/* <div className="card-header col-xl-12 w-100"> */}
                                            <div className="row" style={{ paddingBottom: "10px" }}>
                                                <div className="col-xl-6">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h4 className="card-heading p-2 m-0">Suspend Users</h4>
                                                        <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                                        <form className="app-search card-title p-2 m-0">
                                                            <div className="position-relative">
                                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 text-end">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative btn-suspend">
                                                                <a className='text-white' onClick={() => navigate(PageLinks.USERS, { state: true })}>
                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                    Suspend User
                                                                </a>
                                                            </div>
                                                        </form>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={true} name="dob" format="dd/MM/yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" onChange={setFromDate} value={fromDate}
                                                                    calendarIcon={
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 69.398 79.312">
                                                                            <path id="Path_23208" data-name="Path 23208" d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z" transform="translate(-308.128)" />
                                                                        </svg>
                                                                    } /> */}
                                                                <DatePicker
                                                                    selected={toData}
                                                                    onChange={(date: Date) => setToData(date)}
                                                                    placeholderText="From Date"
                                                                    className='date-picker-style'
                                                                    customInput={(<CustomInput />)}
                                                                />

                                                            </div>
                                                        </form>
                                                        <h4 className="card-title p-2 m-0">TO</h4>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={false} name="dob" format="dd/MM/yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" onChange={setToData} value={toData}
                                                                    calendarIcon={
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 69.398 79.312">
                                                                            <path id="Path_23208" data-name="Path 23208" d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z" transform="translate(-308.128)" />
                                                                        </svg>
                                                                    } /> */}
                                                                <DatePicker
                                                                    selected={toData}
                                                                    onChange={(date: Date) => setToData(date)}
                                                                    placeholderText="To Date"
                                                                    className='date-picker-style'
                                                                    customInput={(<CustomInput />)}
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                            {/* <div className="card-body"> */}
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive " data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Cust. ID</th>
                                                                <th>Reg. Date</th>
                                                                <th>Reg. Time</th>
                                                                <th>Customer Name</th>
                                                                <th>Start Date</th>
                                                                <th>End Date</th>
                                                                <th>Reason</th>
                                                                <th className='w-20'>Remark</th>
                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {userList.length > 0 ? <>
                                                            {userList.map((res: any, i: any) => (
                                                                <tbody key={i}>
                                                                    <tr >
                                                                        <td> {i + 1} </td>
                                                                        <td>{res.customer_id}</td>
                                                                        <td>{res.users_created_at != null && moment(res.users_created_at).format("DD-MM-YYYY")}</td>
                                                                        <td>{res.users_created_at != null && moment(res.users_created_at).format("hh:mm A")}</td>
                                                                        <td>{res.fname}{" "} {res.mname}{" "} {res.lname}</td>
                                                                        <td>{res.suspended_start_date != null && res.suspended_start_date !== "null" ? <>{res.suspended_start_date}</> : "N/A"}</td>
                                                                        <td>{res.suspended_end_date != null && res.suspended_end_date !== "null" ? <>{res.suspended_end_date}</> : "N/A"}</td>
                                                                        <td>{res.account_status_reason != null && res.account_status_reason !== "null" ? <>{res.account_status_reason}</> : "N/A"}</td>
                                                                        <td>{res.account_status_remarks != null && res.account_status_remarks !== "null" ? <p className='m-0 text-content'>{res.account_status_remarks}</p> : "N/A"} {res.account_status_remarks.length > 80 && <span className='text-red cursor-pointer' onClick={(e) => view_more_content(res.account_status_remarks)} >(View more)</span>}</td>

                                                                        <td className="city-action-rable-row ">
                                                                            <i className="fas fa-eye table-icons pe-1" onClick={() => navigate(PageLinks.USERSDETAILS, { state: res.user_specific_id })}></i>

                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" className='pb-2 cursor-pointer' height="22" viewBox="0 0 73.281 58.625" onClick={() => activate_user(res.user_specific_id)} >
                                                                                <path id="Path_22594" data-name="Path 22594" d="M-1807.294,904.684v2.931a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-2.931a15.394,15.394,0,0,1,15.39-15.389c3.3,0,4.866,1.832,10.259,1.832s6.962-1.832,10.259-1.832A15.393,15.393,0,0,1-1807.294,904.684Zm-5.5,0a9.909,9.909,0,0,0-9.893-9.893c-1.683,0-4.339,1.832-10.259,1.832-5.874,0-8.588-1.832-10.259-1.832a9.911,9.911,0,0,0-9.894,9.893v2.931h40.3Zm-36.641-33.709a16.494,16.494,0,0,1,16.489-16.489,16.493,16.493,0,0,1,16.488,16.489,16.493,16.493,0,0,1-16.488,16.488A16.493,16.493,0,0,1-1849.431,870.975Zm5.5,0a11.01,11.01,0,0,0,10.993,10.992,11.01,11.01,0,0,0,10.992-10.992,11.01,11.01,0,0,0-10.992-10.993A11.011,11.011,0,0,0-1843.935,870.975Zm58.282,3.641-14.839,14.919a1.168,1.168,0,0,1-1.672,0l-8.45-8.507a1.2,1.2,0,0,1,0-1.683l2.2-2.2a1.169,1.169,0,0,1,1.672,0l5.428,5.462,11.8-11.874a1.169,1.169,0,0,1,1.672,0l2.187,2.2A1.2,1.2,0,0,1-1785.653,874.616Z" transform="translate(1858.591 -854.486)" />
                                                                            </svg>
                                                                        </td>
                                                                    </tr>

                                                                </tbody>

                                                            ))}
                                                        </> : <tbody>
                                                            <tr className='py-4'>
                                                                <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                            </tr>
                                                        </tbody>}


                                                    </table>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-5">
                                                    {totalCount > 10 ?
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {userList.length} of {totalCount} entries</div> :
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                                        <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                                            setPageNumber(Number(value))
                                                        }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <SimpleModal contents={view_content} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" />

        </div>
    );
}

export default Users;

