import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { Depository, DistributorCategoryStatus, PageLinks } from '../common/Constants';
import { APIData } from '../common/DataTypes';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import { getDisplayData, getFormattedLocalDateTime } from '../common/Utilities';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';


const BrokerageCategoryList: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [userList, setUserList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [brokerageCategoryList, setBrokerageCategoryList] = useState<APIData.BrokerageCategoryList[]>([]);

    useEffect(() => {
        brokerageCategorylist();
    }, [pageNumber, search_text, toData, fromDate])


    const brokerageCategorylist = () => {
        setShowPreloader(true);
        ServerAPI.BrokerageCategoryList(pageNumber, search_text, toData, fromDate).then((res) => {
            setBrokerageCategoryList(res.distributor_types);
            setTotalCount(res.total_count);

        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Masters </a></li>
                                                <li className="breadcrumb-item"><a> Bond Brokerage Category </a></li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <div className="row" style={{ paddingBottom: "10px" }}>
                                                <div className="col-xl-7">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                                        <form className="app-search card-title p-2 m-0">
                                                            <div className="position-relative">
                                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="col-xl-5 text-end">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <form className="app-search card-title p-2 m-0 w-50 text-center" style={{ fontSize: "0.78rem" }}>
                                                            <div className="position-relative btn-suspend">
                                                                <a className='text-white' onClick={() => navigate(PageLinks.BOND_BROKERAGE_CATEGORIE_ADD, { state: { mode: 'add' } })}>
                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                    Add
                                                                </a>
                                                            </div>
                                                        </form>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                <DatePicker
                                                                    selected={fromDate}
                                                                    onChange={(date: Date) => setFromDate(date)}
                                                                    placeholderText="From Date"
                                                                    className='date-picker-style'
                                                                    customInput={(<CustomInput />)}
                                                                />

                                                            </div>
                                                        </form>
                                                        <h4 className="card-title p-2 m-0">TO</h4>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                <DatePicker
                                                                    selected={toData}
                                                                    onChange={(date: Date) => setToData(date)}
                                                                    placeholderText="To Date"
                                                                    className='date-picker-style'
                                                                    customInput={(<CustomInput />)}
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive " data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Created Date</th>
                                                                <th>Last Updated</th>
                                                                <th colSpan={2}>Brokerage Category</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            brokerageCategoryList != undefined && brokerageCategoryList.length > 0 ?
                                                                <>
                                                                    {
                                                                        brokerageCategoryList.map((res: APIData.BrokerageCategoryList, i: number) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td> {i + 1} </td>
                                                                                    <td>{getFormattedLocalDateTime(res.distributor_category_created_at)}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.distributor_category_updated_at)}</td>
                                                                                    <td rowSpan={2}>{getDisplayData(res.distributor_category_name)}</td>

                                                                                    <td className="city-action-rable-row  d-flex ">
                                                                                        <img src='../../assets/images/edit-icon.svg' style={{ width: '6%', margin: "0px 5px" }}
                                                                                        //  onClick={() => navigate(PageLinks.USERSDETAILS, { state: res.user_specific_id })}
                                                                                        ></img>
                                                                                        <div className="form-check form-switch mx-2">
                                                                                            <input className="form-check-input " type="checkbox" role="switch" id="dealerStatus"
                                                                                                // onChange={(e) => {
                                                                                                //     e.target.checked == true ? res.distributor_type_status = 1 : res.distributor_type_status = 0;
                                                                                                //     setDealerStatus(e.target.checked == true ? DistributorStatus.Active : DistributorStatus.Disabled, res.distributor_type_status);
                                                                                                //     setdealerList([...dealerList]);
                                                                                                // }}
                                                                                                checked={res.distributor_category_status == DistributorCategoryStatus.Active ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>

                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-5">
                                                    {totalCount > 10 ?
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {userList.length} of {totalCount} entries</div> :
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                                        <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                                            setPageNumber(Number(value))
                                                        }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default BrokerageCategoryList;

